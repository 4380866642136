<template>
	<div class="info-boxes style-2 flex-row no-gutters item-col-4">
		<div class="info-box" :class="[(item.id%2 == 0 )? 'bg-boxes-op1':'bg-boxes-op2']"  v-for="item in list" :key="item.id">
			<h3 class="box-title">
				<a :href="item.url">{{item.title}}</a>
			</h3>
			<p>{{item.text}}</p>
			<a :href="item.url"  class="btn btn-style-2">Leer más</a>
		</div>
	</div>
</template>
<script>
export default {
	props:{
		list:Array
	}
}
</script>
<style scoped>
[class*="btn"].btn-style-2 {
    background: #f1f1f1;
    color: #333 !important;
    border: 0px;
}
</style>