<template>
	<!-- - - - - - - - - - - - - - Breadcrumbs - - - - - - - - - - - - - - - - -->
	<div class="breadcrumbs-wrap">
		<div class="container">
			<h1 class="page-title" v-html="name" ></h1>
			<ul class="breadcrumbs">
				<li v-for="item in list" :key="item.id">
					<a :href="item.url" >{{item.name}}</a>
				</li>
			</ul>
		</div>
	</div>
	<!-- - - - - - - - - - - - - end Breadcrumbs - - - - - - - - - - - - - - - -->    
</template>
<script>
export default {
	props:{
		name:String,
		list:{
			name:String,
			url:String
		}
	}
}
</script>