<template>
  <div class="menu-holder">
    <div class="menu-wrap">
      <div class="nav-item">
        <!-- - - - - - - - - - - - - - Navigation - - - - - - - - - - - - - - - - -->
        <button id="responsive-nav-button" class="responsive-nav-button" @click="menuResponsive"></button>
        <nav id="main-navigation" class="main-navigation">
          <ul id="menu" class="clearfix">
            <li class="current">
              <a href="https://store.divinorum.com.mx/">Home</a>
            </li>
            <li>
              <a href="#">¿Quiénes somos?</a>
              <!--sub menu-->
              <div class="sub-menu-wrap">
                <ul>
                  <li><a href="/about_us">Nosotros</a></li>
                  <!-- <li><a href="/team">Nuestro Equipo</a></li> -->
                </ul>
              </div>
            </li>
            <li><a href="/courses">Cursos y talleres</a></li>
            <li>
              <a href="/services/single_page/spa">Servicios</a>
            </li>
            <!-- <li>
              <a href="/blog">Blog</a>
            </li>             -->
            <li><a href="https://divinorumboutiqueherbal.alegratienda.com/">Tienda en linea</a>
            </li>          
          </ul>
        </nav>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  
  methods: {
    menuResponsive()
    {
      this.$emit('showMenu')
    }
  }
}
</script>