<template>
    <div>
        <Header/>
        <Breadcrumbs :name="nombre" :list="lista" />
        <!-- - - - - - - - - - - - - - Content - - - - - - - - - - - - - - - - -->
        <div id="content">
            <!-- Page section -->
            <div class="page-section">
                <div class="container">
                    <div class="row align-items-center">
                        <div class="col-lg-6 col-md-12">
                            <h3 class="section-title">Acerca de nosotros</h3>
                            <div class="content-element3">
                                <p>En Divinorum Boutique Herbal combinamos lo mejor de los saberes de la medicina tradicional y la herbolaria con los avances más recientes de la ciencia y la medicina para elaborar productos terapéuticos, cosméticos y nutracéuticos funcionales, amigables con el cuerpo y el medio ambiente.</p>
                                <p>Desarrollamos una amplia gama de servicios y terapias alternativas de carácter holístico e integral a base de cannabis, en sinergia con la medicina tradicional mexicana y oriental, diseñadas especialmente para proporcionarte alivio y bienestar físico, mental y emocional. </p>
                                <p>Además, contamos con un programa avanzado de educación, capacitación y formación en herbolaria, fitocosmética y medicina cannábica, compuesto por diversos cursos y talleres impartidos por profesionales especializados en el área. </p>
                            </div>
                            <!-- <a href="team" class="btn btn-style-2">Conoce a nuestro equipo</a> -->
                        </div>
                        <div class="col-lg-6 col-md-12">
                            <div class="video-holder">
                                <a href="https://www.youtube.com/embed/oX6I6vs1EFs?rel=0&amp;showinfo=0&amp;autohide=2&amp;controls=0&amp;playlist=J2Y_ld0KL-4&amp;enablejsapi=1" class="video-btn" data-fancybox="video"></a>
                                <img src="../assets/960x884_bg1.jpg" alt="">
                                 
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Page section -->
        </div>
        <Footer/>
    </div>    
</template>
<script>
import Header from './website/header/Header';
import Footer from './website/footer/Footer';
import Breadcrumbs from "./elements/Breadcrumbs"


export default {
    data:function(){
        return {
            nombre:"Nosotros",
            lista:[{
                name:"Home",
                url:"/",
            },{
                name:"Nosotros",
                url:"/about_us",
            }]
        }
    },
    components: {
        Header,
        Breadcrumbs,
        Footer
    }
}
</script>
<style scoped>
[class*="btn"].btn-style-2 {
    background: #f1f1f1;
    color: #333 !important;
    border: 0px;
}
</style>