<template>
    <Header/>

    <!-- - - - - - - - - - - - - - Breadcrumbs - - - - - - - - - - - - - - - - -->

    <div class="breadcrumbs-wrap">

      <div class="container">

        <h1 class="page-title">Blog</h1>

        <ul class="breadcrumbs">

          <li><a href="/">Home</a></li>
          <li>Blog</li>

        </ul>

      </div>

    </div>

    <!-- - - - - - - - - - - - - end Breadcrumbs - - - - - - - - - - - - - - - -->    

    <!-- - - - - - - - - - - - - - Content - - - - - - - - - - - - - - - - -->

    <div id="content" class="page-content-wrap">

      <div class="container">

        <div class="row">

          <main id="main" class="col-lg-8 col-md-12">

            <div class="content-element5">

              <div class="entry-box list-type half-col">

                <!-- Entry -->
                <div class="entry" v-for="post in posts" :key="post.id">

                  <div class="label-top">{{post.category}}</div>

                  <!-- - - - - - - - - - - - - - Entry attachment - - - - - - - - - - - - - - - - -->
                  <div class="thumbnail-attachment">
                    <a href="#" v-html="post.image">
                    </a>
                    <div class="entry-label">News</div>
                  </div>

                  <!-- - - - - - - - - - - - - - Entry body - - - - - - - - - - - - - - - - -->
                  <div class="entry-body">
                    <h5 class="entry-title"><a :href="`/blog/${post.ID}`" v-text="post.title"></a></h5>
                    <div class="entry-meta">
                      <time class="entry-date" datetime="post.date">{{ post.date }} </time>
                      <span>por </span>
                      <a href="#" class="entry-cat" v-text="post.author"></a>
                    </div>
                    <!--div class="mb-2">{{ shortContent(post.content) }}</div-->
                    <div v-html="post.excerpt" class="mb-2"></div>
                    <div class="flex-row justify-content-between">
                      <a :href="`/blog/${post.ID}`" class="btn btn-small btn-style-4">Read More</a>
                      <!--a href="#" class="entry-icon"><i class="licon-share"></i></a-->
                    </div>
                  </div>

                </div>

              </div>

            </div>

            <ul class="pagination">
              <li v-if="prevPageToken"><a href="#" class="prev-page" @click="prevPage"></a></li>
              <!--li><a href="#">1</a></li>
              <li class="active"><a href="#">2</a></li>
              <li><a href="#">3</a></li-->
              <li v-if="nextPageToken"><a href="#" class="next-page" @click="nextPage"></a></li>
            </ul>

          </main>
          <aside id="sidebar" class="col-lg-4 col-md-12">

            <!-- Widget -->
            <div class="widget">
            
              <h6 class="widget-title">Like Us On Facebook</h6>

              <div id="fb-root"></div>
              <!--script>(function(d, s, id) {
                var js, fjs = d.getElementsByTagName(s)[0];
                if (d.getElementById(id)) return;
                js = d.createElement(s); js.id = id;
                js.src = 'https://connect.facebook.net/en_US/sdk.js#xfbml=1&version=v2.11&appId=480919338591874';
                fjs.parentNode.insertBefore(js, fjs);
              }(document, 'script', 'facebook-jssdk'));</script-->

              <div class="fb-page" data-href="https://www.facebook.com/DivinorumBotiqueHerbal" data-width="300" data-height="205" data-small-header="true" data-adapt-container-width="true" data-hide-cover="false" data-show-facepile="true"></div>

            </div>

            <!--Nuestros Cursos-->
            <div class="widget">
							<h6 class="widget-title">Nuestros Cursos</h6>
							<div class="entry-box entry-small style-2">
								<div class="entry" v-for="course in entradas" :key="course.id">
									<!-- - - - - - - - - - - - - - Entry attachment - - - - - - - - - - - - - - - - -->
									<a class="thumbnail-attachment">
										<img :src="course.img" :alt="course.alt" style="max-width: 100px;">
									</a>
									<!-- - - - - - - - - - - - - - Entry body - - - - - - - - - - - - - - - - -->
									<div class="entry-body">
										<h6 class="entry-title"><a v-bind:href="'/courses/'+course.id">{{course.title}}</a></h6>
										<div class="entry-meta">
											<span>{{course.location}}</span>
											<br>
											Con: <a class="entry-cat">{{course.instructor}}</a>
										</div>
									</div>
								</div>
							</div>
						</div>
            <!--FIN NUESTROS CURSOS-->

            <!-- Widget -->
            <div class="widget" v-if="labels.length > 0">
              <h6 class="widget-title">Tags</h6>
              <div class="tagcloud">
                <a href="#" v-for="(label, index) of labels" :key="index" v-text="label"></a>
              </div>
            </div>

          </aside>
        </div>
      </div>
    </div>
    <!-- - - - - - - - - - - - - end Content - - - - - - - - - - - - - - - -->
  <Footer/>
</template>

<script>
import Header from '../header/Header';
import axios from 'axios';
import Footer from '../footer/Footer';


export default {
    components: {
        Header,
        Footer
    },
    data(){
      return {
        posts: {},
        labels: [],
        nextPageToken: '',
        prevPageToken: false,
        contNextPage: 0,
        paginate: [],
        keyApi: 'AIzaSyBxYlJqR7PD1ubH00UMHjszJlB6LRpyzvo',
        maxResultsApi: 1,
        blogId: '8002308099204365303',
        entradas:[
				{
					id:1,
					title:"Cannabis medicinal: extracción de aceite para uso terapéutico",
					img:"/img/medicinal.jpg",
					alt:"Spa",
					date:"",
					location:"Online",
					instructor:"Dra. Paulina Mejía",
					body:"<p>En este curso intensivo conocerás las propiedades terapéuticas del cannabis y sus aplicaciones para el tratamiento de diversas enfermedades como fibromialgia, esclerosis múltiple, cáncer, diabetes, artritis, reumatismo, colitis, afecciones de la piel, entre muchas otras. </p>",
					content:"<p>En este curso intensivo conocerás las propiedades terapéuticas del cannabis y sus aplicaciones para el tratamiento de diversas enfermedades como fibromialgia, esclerosis múltiple, cáncer, diabetes, artritis, reumatismo, colitis, afecciones de la piel, entre muchas otras.</p>\
					<p>Aprenderás múltiples aspectos de la medicina cannábica, desde la biología de la planta hasta las principales vías de administración y dosificación, así como la extracción de los principios activos y elaboración del aceite sublingual.</p>\
					<p><strong>Contenido:</strong></p>\
					<p><ol>\
						<li>Biología del cannabis</li>\
						<li>Sistema endocannabinoide</li>\
						<li>Aplicaciones terapéuticas</li>\
						<li>Farmacocinética y vías de administración</li>\
						<li>Dosificación</li>\
						<li>Precauciones y contraindicaciones</li>\
						<li>Extracción de cannabinoides</li>\
						<li>Elaboración de aceite sublingual</li>\
					</ol></p>\
					<p><strong>Instructora:</strong></p>\
					<p>Paulina Mejía Correa es bióloga por la Universidad de Antioquia (Colombia), y egresada del doctorado en Ecología Tropical del Centro de Investigaciones Tropicales (CITRO) de la Universidad Veracruzana. Cuenta además con un diplomado en Cosmética natural por la Benemérita Universidad Autónoma de Puebla.</p>\
					<p>Sus trabajos de investigación se especializan en el área de la etnobiología y manejo de recursos bioculturales. Entre 2010 y 2014 se desempeñó como docente en Medicina tradicional andina, fitoterapia y herbolaria a nivel superior en Ecuador.</p>\
					<p>Desde el 2016 ha enfocado sus investigaciones en las propiedades terapéuticas de los fitocannabinoides. Ha impartido diversos cursos, talleres y conferencias sobre el uso terapéutico del cannabis en varios estados de la república.</p>\
					<p>Actualmente se desempeña como directora científica del Instituto Multidisciplinario de Investigaciones Fitocannabinoides A.C. (INVECANN), desde donde organiza el Simposio Universitario de Investigación Cannabinoide.</p>\
					<p>También es cofundadora de Divinorum Boutique Herbal, donde trabaja en el área de innovación, formulación y desarrollo de productos cosméticos y terapéuticos a base de cannabis.</p>"
				},{
					id:2,
					title:"Cosmética cannábica",
					img:"/img/cosmetica.jpg",
					alt:"Spa",
					date:"",
					location:"Online",
					instructor:"Dra. Paulina Mejía",
					body:"<p>Conoce los fundamentos científicos de la medicina cannabinoide para los tratamientos tópicos y cómo puede beneficiar tu piel. Aprenderás a realizar diferentes extracciones empleadas en la herbolaria, enfocadas en extracción de fitocannabinoides, y su empleo en la formulación de diversos productos de uso tópico y cosmético.</p>",
					content:"<p>Conoce los fundamentos científicos de la medicina cannabinoide para los tratamientos tópicos y cómo puede beneficiar tu piel. Aprenderás a realizar diferentes extracciones empleadas en la herbolaria, enfocadas en extracción de fitocannabinoides, y su empleo en la formulación de diversos productos de uso tópico y cosmético.</p>\
						<p><strong>Contenido:</strong></p>\
						<p><strong>Modulo 1:</strong></p>\
						<ol>\
						<li>Antecedentes del uso tópico del cannabis.</li>\
						<li>Principios activos del cannabis: cannabinoides, terpenos y flavonoides.</li>\
						<li>Sistema endocannabinoide en la piel.</li>\
						<li>Aplicaciones del cannabis en afecciones cutáneas.</li>\
						<li>Extracción de principios activos: oleato, extracto glicólico y tintura.</li>\
						<li>Preparaciones cosméticas:</li>\
						<ul>\
						<li>Tónico facial</li>\
						<li>Aceite para masajes </li>\
						<li>Bálsamo para problemas de la piel</li>\
						<li>Mascarilla facial</li>\
						<li>Jabón corporal</li>\
						<li>Exfoliante corporal</li>\
						<li>Lubricante íntimo oleoso y acuoso</li>\
						</ul>\
						</ol>\
						<p><strong>Instructora:</strong></p>\
						<p>Paulina Mejía Correa es bióloga por la Universidad de Antioquia (Colombia), y egresada del doctorado en Ecología Tropical del Centro de Investigaciones Tropicales (CITRO) de la Universidad Veracruzana. Cuenta además con un diplomado en Cosmética natural por la Benemérita Universidad Autónoma de Puebla. </p>\
						<p>Sus trabajos de investigación se especializan en el área de la etnobiología y manejo de recursos bioculturales. Entre 2010 y 2014 se desempeñó como docente en Medicina tradicional andina, fitoterapia y herbolaria a nivel superior en Ecuador.</p>\
						<p>Desde el 2016 ha enfocado sus investigaciones en las propiedades terapéuticas de los fitocannabinoides. Ha impartido diversos cursos, talleres y conferencias sobre el uso terapéutico del cannabis en varios estados de la república. </p>\
						<p>Actualmente se desempeña como directora científica del Instituto Multidisciplinario de Investigaciones Fitocannabinoides A.C.  (INVECANN), desde donde organiza el Simposio Universitario de Investigación Cannabinoide.</p>\
						<p>También es cofundadora de Divinorum Boutique Herbal, donde trabaja en el área de innovación, formulación y desarrollo de productos cosméticos y terapéuticos a base de cannabis. </p>"
				},{
					id:3,
					title:"Cosmética Cannabica sólida",
					img:"/img/cosmetica-solida.jpg",
					alt:"Spa",
					date:"",
					location:"Online",
					instructor:"DRA. PAULINA MEJÍA",
					body:"<p>En este taller aprenderás a formular y realizar productos cosméticos sólidos sin empaque, con ingredientes de origen natural, amigables con el cuerpo y el medio ambiente.</p>\
						<p>Enseñaremos cómo extraer los principios activos del cannabis y cómo incorporarlos adecuadamente a tus cosméticos y productos de cuidado corporal.</p>\
						<p><strong>Contenido</strong></p>\
						<ol>\
						<li>Champú sólido</li>\
						<li>Acondicionador sólido</li>\
						<li>Sérum facial sólido</li>\
						<li>Exfoliante sólido</li>\
						<li>Mascarilla sólida</li>\
						<li>Limpiador facial sólido</li>\
						<ol>\
						<p><strong>Instructora:</strong></p>\
						<p>Paulina Mejía Correa es bióloga por la Universidad de Antioquia (Colombia), y egresada del doctorado en Ecología Tropical del Centro de Investigaciones Tropicales (CITRO) de la Universidad Veracruzana. Cuenta además con un diplomado en Cosmética natural por la Benemérita Universidad Autónoma de Puebla.</p>\
						<p>Sus trabajos de investigación se especializan en el área de la etnobiología y manejo de recursos bioculturales. Entre 2010 y 2014 se desempeñó como docente en Medicina tradicional andina, fitoterapia y herbolaria a nivel superior en Ecuador.</p>\
						<p>Desde el 2016 ha enfocado sus investigaciones en las propiedades terapéuticas de los fitocannabinoides. Ha impartido diversos cursos, talleres y conferencias sobre el uso terapéutico del cannabis en varios estados de la república.</p>\
						<p>Actualmente se desempeña como directora científica del Instituto Multidisciplinario de Investigaciones Fitocannabinoides A.C. (INVECANN), desde donde organiza el Simposio Universitario de Investigación Cannabinoide.</p>\
						<p>También es cofundadora de Divinorum Boutique Herbal, donde trabaja en el área de innovación, formulación y desarrollo de productos cosméticos y terapéuticos a base de cannabis.</p>",
					content:"<p>En este taller aprenderás a formular y realizar productos cosméticos sólidos sin empaque, con ingredientes de origen natural, amigables con el cuerpo y el medio ambiente.</p>\
						<p>Enseñaremos cómo extraer los principios activos del cannabis y cómo incorporarlos adecuadamente a tus cosméticos y productos de cuidado corporal.</p>\
						<p><strong>Contenido</strong></p>\
						<ul>\
						<li>Champú sólido</li>\
						<li>Acondicionador sólido</li>\
						<li>Sérum facial sólido</li>\
						<li>Exfoliante sólido</li>\
						<li>Mascarilla sólida</li>\
						<li>Limpiador facial sólido</li>\
						</ul>\
						<p><strong>Instructora:</strong></p>\
						<p>Paulina Mejía Correa es bióloga por la Universidad de Antioquia (Colombia), y egresada del doctorado en Ecología Tropical del Centro de Investigaciones Tropicales (CITRO) de la Universidad Veracruzana. Cuenta además con un diplomado en Cosmética natural por la Benemérita Universidad Autónoma de Puebla.</p>\
						<p>Sus trabajos de investigación se especializan en el área de la etnobiología y manejo de recursos bioculturales. Entre 2010 y 2014 se desempeñó como docente en Medicina tradicional andina, fitoterapia y herbolaria a nivel superior en Ecuador.</p>\
						<p>Desde el 2016 ha enfocado sus investigaciones en las propiedades terapéuticas de los fitocannabinoides. Ha impartido diversos cursos, talleres y conferencias sobre el uso terapéutico del cannabis en varios estados de la república.</p>\
						<p>Actualmente se desempeña como directora científica del Instituto Multidisciplinario de Investigaciones Fitocannabinoides A.C. (INVECANN), desde donde organiza el Simposio Universitario de Investigación Cannabinoide.</p>\
						<p>También es cofundadora de Divinorum Boutique Herbal, donde trabaja en el área de innovación, formulación y desarrollo de productos cosméticos y terapéuticos a base de cannabis.</p>"
				},
				{
					id:4,
					title:"Cocina cannábica",
					img:"/img/cocina.jpg",
					alt:"Spa",
					date:"",
					location:"Online",
					instructor:"Chef Carlos A. Luján",
					body:"<p>En este curso práctico aprenderás a integrar el cannabis en tus alimentos y bebidas, además adquirirás los conocimientos para realizar formulaciones con dosificaciones precisas, para que puedas aprovechar al máximo su potencial nutricional y terapéutico.</p>",
					content:"<p>En este curso práctico aprenderás a integrar el cannabis en tus alimentos y bebidas, además adquirirás los conocimientos para realizar formulaciones con dosificaciones precisas, para que puedas aprovechar al máximo su potencial nutricional y terapéutico.</p><br><p>Contenido:</p><ol><li>Anatomía básica de la planta y su uso en gastronomía.</li><li>Fitocannabinoides y su proceso de extracción</li><li>Dosificación</li><li>Preparación de menú:<ul><li>Mantequilla infusionada</li><li>Aceite infusionado</li><li>Vinagreta</li><li>Chimichurri</li><li>Cannapés</li><li>Ensalada</li><li>Plato fuerte</li><li>Bebida infusionada</li><li>Postre cannábico</li></ul></li></ol><br><p><strong>Instructor:</strong></p><p>El curso lo imparte el Chef Carlos A. Luján, especializado en diferentes áreas de la cocina restaurantera y hotelera, y en la preparación de alimentos de la cocina mexicana, española, italiana, oriental, vegetariana, repostería y panadería. Se ha desempeñado como chef en jefe, encargado de manejo de carta y administración de personal en varios restaurantes en distintos estados de la república. En el área de atención a pacientes se desempeñó elaborando menús para pacientes oncológicos en el hospital de Cancerología (CECAN, Xalapa, Ver.). Actualmente maneja la línea de productos e insumos gastronómicos infusionados con cannabis de “Divinorum Cocina Nutracéutica”, y está a cargo del laboratorio gastronómico “Bhang Lassi” especializado en cocina cannábica.</p>"

				},{
					id:5,
					title:"Cultivo básico de cannabis",
					img:"/img/curso_4.jpg",
					alt:"Spa",
					date:"",
					location:"Online",
					instructor:"Agr. Daniel Serena",
					body:"<p>En este taller aprenderás los principios básicos del cultivo de cannabis para uso terapéutico, aplicables tanto al cultivo en interior y exterior. Conocerás sobre la biología y fenología general de la planta, así como los cuidados básicos de cultivo.</p>",
					content:"<p>En este taller aprenderás los principios básicos del cultivo de cannabis para uso terapéutico, aplicables tanto al cultivo en interior y exterior. Conocerás sobre la biología y fenología general de la planta, así como los cuidados básicos de cultivo.</p><br><p><strong>Contenido:</strong></p><ol><li>Biología y fenología general de cannabis</li><li>Semillas y técnicas de germinación</li><li>Manejo del cultivo de cannabis</li><li>Preparación de sustratos y nutrición de la planta</li><li>Control de plagas y enfermedades</li><li>Cosecha, secado y curado</li></ol><br><p><strong>Instructor:</strong></p><p>El curso lo imparte el Ingeniero agrónomo (UV) Daniel Serena, quien cuenta además con estudios en sistemas agroecológicos. Con más de 5 años de experiencias en el cultivo, estudio e investigación de la planta del cannabis, es especialista en el desarrollo y aplicación de agronutrientes orgánicos, y especialista en fitopatología del cannabis (manejo y control de plagas y enfermedades). Actualmente se desempeña como asesor y consultor en cultivo de cannabis.<p>"
				}
			]
      }
    },
    created(){
      this.getPostList();
      this.paginate.push('');
    },
    methods: {
      getPostList(){
        let me = this;
        // Make a request for a user with a given ID
        axios.get(`https://store.divinorum.com.mx/wp-json/dv/posts`, { 
          params: { key: this.keyApi, maxResults: this.maxResultsApi } 
        })
        .then( function(response) {
          // handle success
          me.nextPageToken = response.data.nextPageToken ? response.data.nextPageToken : '';
          me.posts = response.data;
          //me.findTags(response.data.items);
          me.paginate.push(me.nextPageToken);
          console.log(response);
        })
        .catch(function (error) {
          // handle error
          console.log(error);
        });
      },
      nextPage(){

        this.prevPageToken =  true;
        this.contNextPage += 1;
        
        let me = this;
        // Make a request for a user with a given ID
        axios.get(`https://store.divinorum.com.mx/wp-json/wp/v2/posts`,{
          params: { key: this.keyApi, maxResults: this.maxResultsApi, pageToken: this.nextPageToken }
        })
        .then( function(response) {
          // handle success
          me.nextPageToken = response.data.nextPageToken ? response.data.nextPageToken : '';
          me.posts = response.data;
          me.findTags(response.data.items); 
          
          let isHere = me.paginate.find(function(paginate) {
            if(paginate === me.nextPageToken){
              return true;
            }
          });

          if(isHere == undefined){
            me.paginate.push(me.nextPageToken);
          }

        })
        .catch(function (error) {
          // handle error
          console.log(error);
        });        
      },
      prevPage(){

        let me = this;
        this.contNextPage --;
        
        let paginateToken = this.paginate[this.contNextPage];
        //let pageToken = paginateToken != '' ? '&pageToken='+paginateToken : '';
        this.prevPageToken =  paginateToken != '' ? true : false;
        
        // Make a request for a user with a given ID
        /**/
        /*`https://www.googleapis.com/blogger/v3/blogs/${this.blogId}/posts?${pageToken}`*/
        
        axios.get("https://store.divinorum.com.mx/wp-json/wp/v2/posts",{
          params: { key: this.keyApi, maxResults: this.maxResultsApi }
        })
        .then( function(response) {
          // handle success
          console.log(response);
          me.nextPageToken = response.data.nextPageToken ? response.data.nextPageToken : '';
          
          me.posts = response.data;
          me.findTags(response.data.items);
        })
        .catch(function (error) {
          // handle error
          console.log(error);
        }); 
        /** */
      }
    },
    computed: {
      datePublish(){
        return param => {
          let d = new Date(param);
          let options = {weekday: "long", year: "numeric", month: "long", day: "numeric"};
          let datePublish = d.toLocaleDateString("es-ES", options);

          return datePublish;
        };
      },
      shortContent(){
        return param => {
          let content = param;
          let temporal = document.createElement("div");
          temporal.innerHTML = content;
          let text = temporal.textContent || temporal.innerText || "";
          let shortContent = text.substring(0,100);

          return `${shortContent}...`;
        }
      },
      findTags(){

        return param => {

          let me = this;
          me.labels = [];

          param.map(function(post) {
            if(post.labels != undefined){
              post.labels.map(function(test){
                me.labels.push(test);
              });
            }
          });
        };
      },
      // findImage(){
      //   return param => {
      //     let content = param;
      //     let temporal = document.createElement("div");
      //     temporal.innerHTML = content;
      //     let firstImage = temporal.getElementsByTagName('img')[0];
      //     let rawImgSrc = firstImage ? firstImage.getAttribute("src") : false;

      //     return rawImgSrc;
      //   }
      // },
      
      findImage(){
        return (param) => {
          let featured_media = param;
          let image = 'http://localhost:8080/img/blog_default_small.51db04c8.jpg';
          axios.get(`https://store.divinorum.com.mx/wp-json/wp/v2/media/${featured_media}`, { 
            params: { key: this.keyApi, maxResults: this.maxResultsApi } 
          })
          .then( function(response) {
            // handle success
            image = (response.data.media_details.sizes.curso.source_url !=="")? response.data.media_details.sizes.curso.source_url : 'http://localhost:8080/img/blog_default_small.51db04c8.jpg' ;
          })
          .catch(function (error) {
            // handle error
            console.log(error);
          });
          
          return image;
        }
      },      
    }
}
</script>


<style scoped>
[class*="btn"].btn-style-4 {
    background: #e4e4e4;
    color: #333 !important;
    border: 0px;
}
.thumbnail-attachment img {
    height: auto;
    max-width: 100%;
    margin-top: 15px;
}
</style>
