<template>
  <div class="entry-box list-type">
    <!-- - - - - - - - - - - - - - Entry - - - - - - - - - - - - - - - - -->
    <div class="entry" v-for="entry in entries" :key="entry.id">
      <div class="entry">      
            <!-- - - - - - - - - - - - - - Entry attachment - - - - - - - - - - - - - - - - -->
            <div class="thumbnail-attachment">
              <a v-bind:href="'/courses/'+entry.id"><img :src="entry.img" :alt="entry.alt" style="max-width: 427px;"></a>
            </div>
            
            <!-- - - - - - - - - - - - - - Entry body - - - - - - - - - - - - - - - - -->
            <div class="entry-body">
      
              <h5 class="entry-title"><a v-bind:href="'/courses/'+entry.id">{{entry.title}}</a> <!--<span class="product-price">$3500</span>--></h5>
              <div class="our-info">
                
                <div class="info-item">
                  <i class="licon-clock3"></i>
                  <div class="wrapper">
                    <span>{{ entry.date }}</span>
                  </div>
                </div>
                <div class="info-item">
                  <i class="licon-map-marker"></i>
                  <div class="wrapper">
                    <span>{{ entry.location }}</span>
                  </div>
                </div>
              
              </div>
              <div class="excerpt" v-html="entry.body"></div>

              <a v-bind:href="'/courses/'+entry.id" class="btn btn-small">More Details</a>
      
            </div>
      
          </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    entries: Array,
  }
};
</script>
