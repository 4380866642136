<template>
  <Header />
  <!-- - - - - - - - - - - - - - Breadcrumbs - - - - - - - - - - - - - - - - -->
  <div class="breadcrumbs-wrap no-title">
    <div class="container">
      <ul class="breadcrumbs">
        <li><a href="/">Home</a></li>
        <li><a href="/blog">Blog</a></li>
        <li></li>
      </ul>
    </div>
  </div>
  <!-- - - - - - - - - - - - - end Breadcrumbs - - - - - - - - - - - - - - - -->

  <!-- - - - - - - - - - - - - - Content - - - - - - - - - - - - - - - - -->
  <div id="content" class="page-content-wrap">
    <!--transition name="fade" mode="out-in" appear-->
    <div v-if="loadingPost" class="text-center mt-5">
      <Loading />
    </div>

    <div class="container" v-else>

      <div class="row">

        <main id="main" class="col-lg-8 col-md-12">

          <div class="content-element3">

            <div class="entry-box single-entry">

              <!-- - - - - - - - - - - - - - Entry body - - - - - - - - - - - - - - - - -->
              <div class="entry-body content-element4">

                <h1 class="entry-title"><a href="#" v-text="post.title"></a></h1>
                <div class="entry-meta">
                  <time class="entry-date" datetime="post.updated">{{ post.date }}, publicado </time>
                  <span>por </span>
                  <a href="#" class="entry-cat" v-text="post.author"></a>

                </div>

              </div>

              <!-- Entry -->
              <div class="entry">

                <div class="label-top">Featured</div>

                <!-- - - - - - - - - - - - - - Entry attachment - - - - - - - - - - - - - - - - -->
                <div class="thumbnail-attachment">
                  <a href="#" v-html="post.image"></a>
                  <div class="entry-label">News</div>
                </div>

                <div v-html="post.content" class="mb-2 mt-5"></div>

                <!--div class="blockquote-holder with-bg">
                    <blockquote>
                      <p>“Suspendisse sollicitudin velit sed leo. Ut pharetra augue nec augue. Nam elit agna, endrerit sit amet, tincidunt ac, viverra sed, nulla. Donec porta diam eu massa.”</p>
                      <div class="author">John McCoist</div>
                    </blockquote>
                  </div-->

                <!--p class="content-element4">Vestibulum sed ante. Donec sagittis euismod purus. Sed ut perspiciatis unde omnis iste natus error sit <a href="#" class="link-text">voluptatem</a> accusantium doloremque laudantium, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur.</p-->

                <!--div class="content-element3">
                    
                    <img src="images/334x260_img1.jpg" alt="" class="alignleft">

                    <h5>Tellus Non Erat Lacinia</h5>
                    <p>Lorem ipsum dolor sit amet, consectetuer adipis. Mauris accumsan nulla vel diam. Sed in lacus ut enim adipiscing aliquet. Nulla venenatis. In pede mi, aliquet. </p>
                    <p>Aliquam dapibus tincidunt metus. Praesent justo dolor, lobortis quis, lobortis dignissim, pulvinar ac, lorem.</p>
                    <p>Inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt.</p>

                  </div-->

                <!--div class="flex-row justify-content-between">
                    
                    <div class="tagcloud">

                      <a href="#">accessories</a>
                      <a href="#">bag</a>
                      <a href="#">Beauty</a>

                    </div>

                    <a href="#" class="entry-icon"><span>Share</span><i class="licon-share"></i></a>

                  </div-->

              </div>

            </div>

          </div>

          <!--div class="post-nav">
              <div class="row">
                <div class="col-6">
                  <a href="#" class="link-text">Previous Post</a>
                  <h6><a href="#">Mauris Accumsan Nulla Vel</a></h6>
                </div>
                <div class="col-6">
                  <div class="align-right">
                    <a href="#" class="link-text">Next Post</a>
                    <h6><a href="#">Donec Eget Tellus Non Erat</a></h6>
                  </div>
                </div>
              </div>
            </div-->



        </main>
        <aside id="sidebar" class="col-lg-4 col-md-12">


          <!--div class="widget">
              
              <form class="search-form">
                
                <input type="text" placeholder="Search Products">
                <button type="submit"><i class="licon-magnifier"></i></button>

              </form>

            </div-->

          <!-- Widget -->
          <!--div class="widget">
              
              <h6 class="widget-title">Categories</h6>
              
              <ul class="custom-list style-2">
                <li><a href="#">Beauty</a></li>
                <li><a href="#">Clothes</a></li>
                <li><a href="#">Equipment</a></li>
                <li><a href="#">Food & Drinks</a></li>
              </ul>

            </div-->

          <!-- Widget -->
          <!--div class="widget">
                  
              <h6 class="widget-title">Latest Posts</h6>

              <div class="entry-box entry-small style-2">
            
                
                <div class="entry">
                
                  <div class="thumbnail-attachment">
                    <a href="#"><img src="images/100x78_img1.jpg" alt=""></a>
                  </div>
                  
                  <div class="entry-body">

                    <h6 class="entry-title"><a href="#">Mauris Accumsan Nulla Vel Diam</a></h6>
                    <div class="entry-meta">

                      <time class="entry-date" datetime="2018-11-18">November 18, 2018</time>

                    </div>

                  </div>

                </div>


                <div class="entry">
                
                  
                  <div class="thumbnail-attachment">
                    <a href="#"><img src="images/100x78_img2.jpg" alt=""></a>
                  </div>
                  
                  
                  <div class="entry-body">

                    <h6 class="entry-title"><a href="#">Ut Pharetra Augue Nec Augue</a></h6>
                    <div class="entry-meta">

                      <time class="entry-date" datetime="2018-12-21">Dec 21, 2018, 07:00 AM</time>

                    </div>

                  </div>

                </div>

                
                <div class="entry">
                
                  
                  <div class="thumbnail-attachment">
                    <a href="#"><img src="images/100x78_img3.jpg" alt=""></a>
                  </div>
                  
                  
                  <div class="entry-body">

                    <h6 class="entry-title"><a href="#">Donec Eget Tellus Non Erat Lacinia Fermentum</a></h6>
                    <div class="entry-meta">

                      <time class="entry-date" datetime="2018-11-18">November 18, 2018</time>

                    </div>

                  </div>

                </div>

              </div>

            </div-->

          <!-- Widget -->
          <div class="widget">

            <h6 class="widget-title">Like Us On Facebook</h6>

            <div id="fb-root"></div>
            <!--script>(function(d, s, id) {
                var js, fjs = d.getElementsByTagName(s)[0];
                if (d.getElementById(id)) return;
                js = d.createElement(s); js.id = id;
                js.src = 'https://connect.facebook.net/en_US/sdk.js#xfbml=1&version=v2.11&appId=480919338591874';
                fjs.parentNode.insertBefore(js, fjs);
              }(document, 'script', 'facebook-jssdk'));</script-->

            <!--div class="fb-page" data-href="https://www.facebook.com/envato" data-width="300" data-height="205" data-small-header="true" data-adapt-container-width="true" data-hide-cover="false" data-show-facepile="true"></div-->
            <div class="fb-page" data-href="https://www.facebook.com/DivinorumBotiqueHerbal" data-width="300"
              data-height="205" data-small-header="true" data-adapt-container-width="true" data-hide-cover="false"
              data-show-facepile="true">
            </div>
          </div>

          <!-- Widget -->
          <div class="widget" v-if="post.labels">
            <h6 class="widget-title">Tags</h6>
            <div class="tagcloud">
              <a href="#" v-for="(label, index) of post.labels" :key="index">{{ label }}</a>
            </div>
          </div>
        </aside>

      </div>

    </div>
    <!--/transition-->
  </div>
  <!-- - - - - - - - - - - - - end Content - - - - - - - - - - - - - - - -->
  <Footer />
</template>

<script>
import Header from '../header/Header';
import Loading from '../../elements/Loading';
import axios from 'axios';
import Footer from '../footer/Footer';

export default {
  components: {
    Header,
    Loading,
    Footer

  },
  data() {

    return {
      idPost: this.$route.params.id,
      post: {},
      author: '',
      totalItems: 0,
      loadingPost: true
    }
  },
  created() {
    this.getPost();
  },
  methods: {
    getPost() {
      let me = this;
      // Make a request for a user with a given ID

      //axios.get(`https://www.googleapis.com/blogger/v3/blogs/8002308099204365303/posts/${ this.idPost }?key=AIzaSyBxYlJqR7PD1ubH00UMHjszJlB6LRpyzvo`)
         axios.get(`https://store.divinorum.com.mx/wp-json/dv/post/${this.idPost}`)
        .then(function (response) {
          me.post = response.data;
        })
        .catch(function (error) {
          // handle error
          console.log(error);
        })
        .then(() => {
          me.loadingPost = false;
        });
    }
  },
  computed: {
    datePublish() {
      return param => {
        let d = new Date(param);
        let options = { weekday: "long", year: "numeric", month: "long", day: "numeric" };
        let n = d.toLocaleDateString("es-ES", options);

        return n;
      };
    },
    findImage() {
      return param => {
        let content = param;
        let temporal = document.createElement("div");
        temporal.innerHTML = content;
        let firstImage = temporal.getElementsByTagName('img')[0];
        let rawImgSrc = firstImage ? firstImage.getAttribute("src") : false;

        return rawImgSrc;
      }
    }
  }
}
</script>

<style>
.fade-enter-active,
.fade-leave-active {
  transition: opacity .2s ease;
}

.fade-enter-from,
.fade-leave-to {
  transition: opacity .2s ease-out;
  opacity: 0;
}

.thumbnail-attachment img {
  height: auto;
  max-width: 100%;
}</style>