<template>
    <div>
        <Header/>
        <div class="container-fluid">
            <div class="row">
                <Sidebar :item-select="'User'"  />  
                <main class="offset-md-3  offset-lg-2 col-md-9 ms-sm-auto col-lg-10 px-md-4 pt-3">
                    <Form 
                    :form="user"
                    :url="'http://localhost:5000/user'"
                    callback="'/admin'"
                    />                    
                </main>
            </div>
        </div>
    </div>    
</template>
<script>
import Header from './header/Header';
import Sidebar from './sidebar/Sidebar';
import Form from '../elements/Form';

export default {
    data:function(){
        return {
            user:[{
                index:1,
                label:"Nombre",
                slug:'name',
                type:"text"
            },{
                index:2,
                label:"Primer Apellido",
                slug:'first_surname',
                type:"text"
            },{
                index:3,
                label:"Segundo Apellido",
                slug:'second_surname',
                type:"text"
            },{
                index:4,
                label:"Email",
                slug:'email',
                type:"email"
            },{
                index:5,
                label:"Password",
                slug:'password',
                type:"password"
            }]
        }
    },
    components: {
        Header,
        Sidebar,
        Form
    }
}
</script>
