<template>
    <Header/>

    <!-- - - - - - - - - - - - - - Breadcrumbs - - - - - - - - - - - - - - - - -->

    <div class="breadcrumbs-wrap">
      <div class="container">
        <h1 class="page-title">My Account</h1>
        
        <ul class="breadcrumbs">
          <li><a href="/">Home</a></li>
          <li>Shop</li>
          <li>My Account</li>
        </ul>
      </div>
    </div>

    <!-- - - - - - - - - - - - - end Breadcrumbs - - - - - - - - - - - - - - - -->    

    <!-- - - - - - - - - - - - - - Content - - - - - - - - - - - - - - - - -->

    <div id="content" class="page-content-wrap">

      <div class="container wide3">
        
        <div class="row">
          
          <main id="main" class="col-lg-9 col-md-12">
          
            <div class="content-element">
                
              <h3 class="section-title">Order History</h3>
              <div class="table-type-1 recent-order responsive-table">
                <table>
                  <thead>
                    <tr>
                      <th class="order-number">Order #</th>
                      <th class="order-date">Date</th>
                      <th>Status</th>
                      <th>Total</th>
                      <th class="order-view"></th>
                    </tr>
                  </thead>
                  <tr>
                    <td data-title="Order #">12345</td>
                    <td data-title="Date">December 17, 2018</td>
                    <td data-title="Status">Pending</td>
                    <td data-title="Total">$5.99</td>
                    <td><a href="#" class="info-btn">View</a></td>
                  </tr>
                  <tr class="bg-cell2">
                    <td data-title="Order #">12346</td>
                    <td data-title="Date">December 20, 2018</td>
                    <td data-title="Status">Pending</td>
                    <td data-title="Total">$31.30</td>
                    <td><a href="#" class="info-btn">View</a></td>
                  </tr>
                  <tr>
                    <td data-title="Order #">12347</td>
                    <td data-title="Date">December 15, 2018</td>
                    <td data-title="Status">Pending</td>
                    <td data-title="Total">$96.27</td>
                    <td><a href="#" class="info-btn">View</a></td>
                  </tr>
                </table>
              </div>

            </div>

            <div class="content-element">
              
              <div class="content-element4">
                
                <h3>My Dashboard</h3>
                <p class="fw-medium text-size-medium">Hello, John Doe (not John Doe? <a href="#" class="link-text">Sign out</a>)</p>
                <p>From your account dashboard you can view your recent orders, manage your shipping and billing addresses and <a href="#" class="link-text">edit your password and account details.</a> </p>

              </div>

              <div class="content-element4">
                
                <p class="fw-medium text-size-medium">My Addresses</p>
                <p>The following addresses will be used on the checkout page by default. </p>

              </div>

              <div class="content-element4">
                
                <div class="row">
                  <div class="col-sm-6">
                    
                    <p class="fw-medium text-size-medium content-element1">Billing Address</p>
                    <ul class="fw-light content-element1">
                      <li>John Doe</li>
                      <li>Green street</li>
                      <li>12345 New York</li>
                      <li>USA</li>
                    </ul>
                    <a href="#" class="btn btn-small btn-style-4">Edit</a>

                  </div>
                  <div class="col-sm-6">
                    
                    <p class="fw-medium text-size-medium content-element1">Shipping Address</p>
                    <ul class="fw-light content-element1">
                      <li>John Doe</li>
                      <li>Green street</li>
                      <li>12345 New York</li>
                      <li>USA</li>
                    </ul>
                    <a href="#" class="btn btn-small btn-style-4">Edit</a>

                  </div>
                </div>

              </div>

            </div>

          </main>

          <aside id="sidebar" class="col-lg-3 col-md-12">
            
            <!-- Widget -->
            <div class="widget">
              
              <form class="search-form">
                
                <input type="text" placeholder="Search Products">
                <button type="submit"><i class="licon-magnifier"></i></button>

              </form>

            </div>

            <!-- Widget -->
            <div class="widget">
              
              <h6 class="widget-title">Categories</h6>
              
              <ul class="custom-list style-2">
                <li><a href="#">Beauty</a></li>
                <li><a href="#">Clothes</a></li>
                <li><a href="#">Equipment</a></li>
                <li><a href="#">Food & Drinks</a></li>
              </ul>

            </div>
            
            <!-- Widget -->
            <div class="widget">
                  
              <h6 class="widget-title">Products</h6>

              <div class="product-holder">
                
                <!-- product -->
                <div class="product">
                    
                  <!-- - - - - - - - - - - - - - Product Image - - - - - - - - - - - - - - - - -->
                  <figure class="product-image">
                    <a href="#"><img src="../../../assets/78x78_img1.jpg" alt=""></a>
                  </figure>
                  <!-- - - - - - - - - - - - - - End of Product Image - - - - - - - - - - - - - - - - -->

                  <!-- - - - - - - - - - - - - - Product Description - - - - - - - - - - - - - - - - -->
                  <div class="product-description">

                    <h6 class="product-name"><a href="#">Non Slip Yoga Mat</a></h6>

                    <div class="pricing-area">

                      <div class="product-price">
                        $19.95
                      </div>

                      <ul class="rating">
                        <li><i class="licon-star"></i></li>
                        <li><i class="licon-star"></i></li>
                        <li><i class="licon-star"></i></li>
                        <li><i class="licon-star"></i></li>
                        <li class="empty"><i class="licon-star"></i></li>
                      </ul>

                    </div>

                  </div>
                  <!-- - - - - - - - - - - - - - End of Product Description - - - - - - - - - - - - - - - - -->

                </div>
                
                <!-- product -->
                <div class="product">
                    
                  <!-- - - - - - - - - - - - - - Product Image - - - - - - - - - - - - - - - - -->
                  <figure class="product-image">
                    <a href="#"><img src="../../../assets/78x78_img3.jpg" alt=""></a>
                  </figure>
                  <!-- - - - - - - - - - - - - - End of Product Image - - - - - - - - - - - - - - - - -->

                  <!-- - - - - - - - - - - - - - Product Description - - - - - - - - - - - - - - - - -->
                  <div class="product-description">

                    <h6 class="product-name"><a href="#">Yoga Shirt Namaste Love</a></h6>

                    <div class="pricing-area">

                      <div class="product-price">
                        <span>$48.95</span> $38.95
                      </div>

                    </div>

                  </div>
                  <!-- - - - - - - - - - - - - - End of Product Description - - - - - - - - - - - - - - - - -->

                </div>

                <!-- product -->
                <div class="product">
                    
                  <!-- - - - - - - - - - - - - - Product Image - - - - - - - - - - - - - - - - -->
                  <figure class="product-image">
                    <a href="#"><img src="../../../assets/78x78_img2.jpg" alt=""></a>
                  </figure>
                  <!-- - - - - - - - - - - - - - End of Product Image - - - - - - - - - - - - - - - - -->

                  <!-- - - - - - - - - - - - - - Product Description - - - - - - - - - - - - - - - - -->
                  <div class="product-description">

                    <h6 class="product-name"><a href="#">Light Hard Foam Yoga Blocks</a></h6>

                    <div class="pricing-area">

                      <div class="product-price">
                        $11.35
                      </div>

                      <ul class="rating">
                        <li><i class="licon-star"></i></li>
                        <li><i class="licon-star"></i></li>
                        <li><i class="licon-star"></i></li>
                        <li><i class="licon-star"></i></li>
                        <li><i class="licon-star"></i></li>
                      </ul>

                    </div>

                  </div>
                  <!-- - - - - - - - - - - - - - End of Product Description - - - - - - - - - - - - - - - - -->

                </div>

              </div>

            </div>
            
            <!-- Widget -->
            <div class="widget">
            
              <h6 class="widget-title">Like Us On Facebook</h6>

              <div id="fb-root"></div>
              <!--script>(function(d, s, id) {
                var js, fjs = d.getElementsByTagName(s)[0];
                if (d.getElementById(id)) return;
                js = d.createElement(s); js.id = id;
                js.src = 'https://connect.facebook.net/en_US/sdk.js#xfbml=1&version=v2.11&appId=480919338591874';
                fjs.parentNode.insertBefore(js, fjs);
              }(document, 'script', 'facebook-jssdk'));</script-->

              <div class="fb-page" data-href="https://www.facebook.com/envato" data-width="300" data-height="205" data-small-header="true" data-adapt-container-width="true" data-hide-cover="false" data-show-facepile="true"></div>

            </div>

            <!-- Widget -->
            <div class="widget">
                
              <h6 class="widget-title">Tags</h6>

              <div class="tagcloud">

                <a href="#">accessories</a>
                <a href="#">bag</a>
                <a href="#">Beauty</a>
                <a href="#">Belt</a>
                <a href="#">blocks</a>
                <a href="#">bra</a>
                <a href="#">clothes</a>
                <a href="#">Cushion</a>
                <a href="#">equipment</a>
                <a href="#">Mat</a>
                <a href="#">t-shirt</a>

              </div>

            </div>

          </aside>

        </div>

      </div>
      
    </div>

    <!-- - - - - - - - - - - - - end Content - - - - - - - - - - - - - - - -->    
</template>

<script>
import Header from '../header/Header';

export default {
    components: {
        Header
    }
}
</script>

<style>

</style>