<template>
	<div class="page-section-bg">
		<div class="container wide2">
			<div class="content-element4">
				<div class="section-pre-title">Expertos</div>
				<h2 class="section-title">Conoce nuestro equipo</h2>
			</div>
		</div>
		<div class="carousel-type-2">
			<Carousel >
				<Slide v-for="t in team" :key="t.id">
					<div class="team-item">
						<a  class="member-photo">
							<img v-bind:src="t.image" alt="" />
						</a>
						<div class="team-desc">
							<div class="member-info">
								<h5 class="member-name">
									<a >{{t.name}}</a>
								</h5>
								<h6 class="member-position">
									{{t.academictitle}}
								</h6>
							</div>
						</div>
					</div>
				</Slide>
			</Carousel>
		</div>
	</div>
</template>
<script>
import 'vue3-carousel/dist/carousel.css';
import { Carousel, Slide } from 'vue3-carousel';

export default {
	props:{
		team:Array
	},
	components:{
		Carousel,
		Slide
	}
}
</script>
