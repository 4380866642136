<template>
    <Header/>

    <!-- - - - - - - - - - - - - - Breadcrumbs - - - - - - - - - - - - - - - - -->

    <div class="breadcrumbs-wrap">

      <div class="container">

        <h1 class="page-title">Cart</h1>
        
        <ul class="breadcrumbs">

          <li><a href="/">Home</a></li>
          <li>Shop</li>
          <li>Cart</li>

        </ul>

      </div>

    </div>

    <!-- - - - - - - - - - - - - end Breadcrumbs - - - - - - - - - - - - - - - -->

    <!-- - - - - - - - - - - - - - Content - - - - - - - - - - - - - - - - -->

    <div id="content" class="page-content-wrap">

      <div class="container wide3">
        
        <div class="content-element8">
          
          <div class="shop-cart-form table-type-1 responsive-table">
            <table>
              <thead>
                <tr>
                  <th class="product-col">Product</th>
                  <th class="price-col">Price</th>
                  <th class="qty-col">Quantity</th>
                  <th class="total-col">Total</th>
                </tr>
              </thead>
              <tr>
                <td class="close-product shopping-cart-full" data-title="Product">
                  
                  <button class="item-close"></button>
                  <div class="product">

                    <a href="#" class="product-image">
                      
                      <img src="../../../assets/78x78_img1.jpg" alt="">

                    </a>

                    <div class="product-description">

                      <h6 class="product-title"><a href="#">Non Slip Yoga Mat</a></h6>

                    </div><!--/ .product-info -->

                  </div>

                </td>
                <td data-title="Price">$19.95</td>
                <td data-title="Quantity">1</td>
                <td data-title="Total" class="total-price">$19.95</td>
              </tr>
              <tr>
                <td class="close-product shopping-cart-full" data-title="Product">
                  
                  <button class="item-close"></button>
                  <div class="product">

                    <a href="#" class="product-image">
                      
                      <img src="../../../assets/78x78_img2.jpg" alt="">

                    </a>

                    <div class="product-description">

                      <h6 class="product-title"><a href="#">Light Hard Foam <br> Yoga Blocks</a></h6>

                    </div><!--/ .product-info -->

                  </div>

                </td>
                <td data-title="Price">$19.95</td>
                <td data-title="Quantity">1</td>
                <td data-title="Total" class="total-price">$19.95</td>
              </tr>
              <tr>
                <td colspan="2">

                  <form class="newsletter">
                    <input type="email" name="newsletter-email" placeholder="Enter coupon code">
                    <button type="submit" class="btn btn-big">Apply Coupon</button>
                  </form>

                </td>
                <td colspan="2">
                  <div class="align-right">
                    <button class="btn btn-big">Update Cart</button>
                  </div>
                </td>
              </tr>
            </table>
          </div>

        </div>

        <div class="content-element8">
          
          <div class="row">
            <div class="col-lg-6 col-md-12">

              <h4 class="section-title">You May Be Interested In...</h4>
              
              <div class="products-holder flex-row item-col-2">

                <div class="product">
                      
                  <!-- - - - - - - - - - - - - - Product Image - - - - - - - - - - - - - - - - -->
                  <figure class="product-image">
                    <span class="label sale">Sale</span>
                    <a href="#"><img src="../../../assets/289x286_img9.jpg" alt=""></a>
                  </figure>
                  <!-- - - - - - - - - - - - - - End of Product Image - - - - - - - - - - - - - - - - -->
              
                  <!-- - - - - - - - - - - - - - Product Description - - - - - - - - - - - - - - - - -->
                  <div class="product-description">
              
                    <a href="#" class="product-cat">Equipment</a>
              
                    <h6 class="product-name"><a href="#">Big Yoga Bag</a></h6>
              
                    <div class="pricing-area">
              
                      <div class="product-price">
                        <span>$48.95</span> $38.95
                      </div>
              
                      <ul class="rating">
                        <li><i class="licon-star"></i></li>
                        <li><i class="licon-star"></i></li>
                        <li><i class="licon-star"></i></li>
                        <li><i class="licon-star"></i></li>
                        <li><i class="licon-star"></i></li>
                      </ul>
              
                    </div>
              
                    <a href="#" class="btn btn-small btn-style-3">Add To Cart</a>
              
                  </div>
                  <!-- - - - - - - - - - - - - - End of Product Description - - - - - - - - - - - - - - - - -->
              
                </div>

                <div class="product">
                      
                  <!-- - - - - - - - - - - - - - Product Image - - - - - - - - - - - - - - - - -->
                  <figure class="product-image">
                    <a href="#"><img src="../../../assets/289x286_img10.jpg" alt=""></a>
                  </figure>
                  <!-- - - - - - - - - - - - - - End of Product Image - - - - - - - - - - - - - - - - -->
              
                  <!-- - - - - - - - - - - - - - Product Description - - - - - - - - - - - - - - - - -->
                  <div class="product-description">
              
                    <a href="#" class="product-cat">Clothes</a>
              
                    <h6 class="product-name"><a href="#">Sport Yoga Bra</a></h6>
              
                    <div class="pricing-area">
              
                      <div class="product-price">
                        $14.99
                      </div>
              
                      <ul class="rating">
                        <li><i class="licon-star"></i></li>
                        <li><i class="licon-star"></i></li>
                        <li><i class="licon-star"></i></li>
                        <li><i class="licon-star"></i></li>
                        <li><i class="licon-star"></i></li>
                      </ul>
              
                    </div>
              
                    <a href="#" class="btn btn-small btn-style-3">Add To Cart</a>
              
                  </div>
                  <!-- - - - - - - - - - - - - - End of Product Description - - - - - - - - - - - - - - - - -->
              
                </div>
              
              </div>

            </div>
            <div class="col-lg-6 col-md-12">
              
              <h4 class="section-title">Cart Totals</h4>

              <div class="table-type-2 content-element4">
                <table>
                  <tbody>
                    <tr>
                      <th>Subtotal</th>
                      <td class="price">$31.30</td>
                    </tr>
                    <tr>
                      <th>Shipping</th>
                      <td>
                        <div class="content-element2">
                          
                          <div class="input-wrapper">

                            <input type="radio" name="radio-1" checked="" id="radio-1">
                            <label for="radio-1">Free shipping</label>

                          </div>
                          <div class="input-wrapper">

                            <input type="radio" name="radio-1" id="radio-2">
                            <label for="radio-2">Flat shipping</label>

                          </div>

                        </div>
                        <a href="#" class="link-text var3">Calculate Shipping</a>
                      </td>
                    </tr>
                    <tr>
                      <th class="fw-medium">Total</th>
                      <td class="tprice fw-medium">$31.30</td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div class="align-right">
                <a href="#" class="btn btn-style-3 btn-lg">Proceed to Checkout</a>
              </div>

            </div>
          </div>

        </div>

      </div>
      
    </div>

    <!-- - - - - - - - - - - - - end Content - - - - - - - - - - - - - - - -->    

</template>

<script>
import Header from '../header/Header';

export default {
    components: {
        Header
    }
}
</script>

<style>

</style>