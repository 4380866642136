<template>
	<!-- - - - - - - - - - - - - Mobile Menu - - - - - - - - - - - - - - -->
	<a id="advanced-menu-hide" href="#" :style="visi" @click="closeMenuRes"></a>
	<nav id="mobile-advanced" class="mobile-advanced" :style="styleObject">
          <ul id="menu" class="clearfix">
            <li class="current">
              <a href="https://store.divinorum.com.mx/">Inicio</a>
            </li>
            <li>
              <a href="#">¿Quiénes somos?</a>
              <!--sub menu-->
              <div class="sub-menu-wrap">
                <ul>
                  <li><a href="/about_us">Nosotros</a></li>
                  <!-- <li><a href="/team">Nuestro Equipo</a></li> -->
                </ul>
              </div>
            </li>
            <li><a href="/courses">Cursos y talleres</a></li>
            <li>
              <a href="/services/single_page/spa">Servicios</a>
            </li>
			<!-- <li>
              <a href="/blog">Blog</a>
            </li> -->
            <li><!-- <a href="/shop_category">Tienda en linea</a> -->
				<a href="https://divinorumboutiqueherbal.alegratienda.com/">Tienda en linea</a></li>          
          </ul>
	</nav>

	<header id="header" class="header sticky-header sticky">
		<div class="top-header">
			<div class="flex-row align-items-center justify-content-between">
				<!--header class="row mb-3"-->
					<!--div class="col-2 logo-wrap"-->
					<div class="logo-wrap">
						<a href="/" class="logo">
							<img src="../../../assets/logoa.png" alt="">
						</a>
					</div>
					<MainMenu class="" v-on:show-menu="shoMenuRes" />		
				<!--/header-->
			</div>
		</div>
	</header>
</template>
<script>

// import SearchForm from './SearchForm';
import MainMenu from './MainMenu';

export default {
	components: {
		// SearchForm,
		MainMenu
	},
	data(){
		return{
			styleObject: {
				display: 'none'
			},
			visi: {
				visibility: 'hidden'
			}
		}
	},
	methods: {
		shoMenuRes(){
			this.styleObject = {display: 'block', right: '0px', "pointer-events": 'auto'}
			this.visi = {visibility: 'visible', right: '90px', "pointer-events": 'auto'}
		},
		closeMenuRes(){
			this.styleObject = {display: 'none', right: '0px'}
			this.visi = {visibility: 'hidden'/*, right: '90px', "pointer-events": 'auto'*/}
		}
	}
	
}
</script>