<template>
    <Header/>

    <!-- - - - - - - - - - - - - - Breadcrumbs - - - - - - - - - - - - - - - - -->

    <div class="breadcrumbs-wrap">
      <div class="container">
        <h1 class="page-title">Checkout</h1>
        
        <ul class="breadcrumbs">
          <li><a href="/">Home</a></li>
          <li>Shop</li>
          <li>Checkout</li>
        </ul>
      </div>
    </div>

    <!-- - - - - - - - - - - - - end Breadcrumbs - - - - - - - - - - - - - - - -->    

    <!-- - - - - - - - - - - - - - Content - - - - - - - - - - - - - - - - -->

    <div id="content" class="page-content-wrap">

      <div class="container wide3">
        
        <div class="content-element4">
          
          <div class="hidden-section">

            <div class="account-action">Returning customer? <a class="invoker" href="#">Click here to login.</a></div>

            <div class="content">

              <p>If you have shopped with us before, please enter your details in the boxes below. If you are a new customer please proceed to the Billing & Shipping section.</p>

              <!-- - - - - - - - - - - - - - Login Form - - - - - - - - - - - - - - - - -->

              <form class="contact-form style-2">

                <div class="contact-item">
                  <div class="row">
                    <div class="col-sm-6">
                      
                      <label class="required">Username or email</label>
                      <input type="text">

                       <a href="#" class="btn">Login</a>
                      
                    </div>
                    <div class="col-sm-6">
                      
                      <label class="required">Password</label>
                      <input type="text" class="content-element1">
                      <footer class="login-form-footer">
                  
                        <input type="checkbox" id="login-remember-me" name="login-remember-me">
                        <label for="login-remember-me">Remember me</label>

                        <a href="#" class="link-text">Lost your password?</a>
                  
                      </footer>
                  
                    </div>
                  </div>
                </div>

              </form>

              <!-- - - - - - - - - - - - - - End of Login Form - - - - - - - - - - - - - - - - -->

            </div><!--/ .content -->

          </div><!--/ .hidden-section -->

          <div class="hidden-section">

            <div class="account-action">Have a coupon? <a class="invoker" href="#">Click here to enter your code.</a></div>

            <div class="content">

              <div class="row">
                <div class="col-lg-6">

                  <!-- - - - - - - - - - - - - - Coupon Form - - - - - - - - - - - - - - - - -->

                  <div class="join-us style-3">
                    <form class="newsletter style-2">
                      <input type="email" name="newsletter-email" placeholder="Enter coupon code">
                      <button type="submit" data-type="submit" class="btn btn-big">Apply Coupon</button>
                    </form>
                  </div>

                  <!-- - - - - - - - - - - - - - End of Coupon Form - - - - - - - - - - - - - - - - -->

                </div>
              </div>

            </div><!--/ .content -->

          </div><!--/ .hidden-section -->

        </div>

        <div class="content-element">
          
          <div class="row">
            <div class="col-md-6 col-sm-12">
              
              <h5>Billing Details</h5>
              <form class="contact-form style-2">

                <div class="contact-item">
                  
                  <div class="row">
                    <div class="col-sm-6">
                      
                      <label class="required">First Name</label>
                      <input type="text">

                    </div>
                    <div class="col-sm-6">
                      
                      <label class="required">Last Name</label>
                      <input type="text">

                    </div>
                  </div>

                </div>

                <div class="contact-item">
                  
                  <label>Company Name</label>
                  <input type="text">

                </div>

                <div class="contact-item">
                  
                  <label class="required">Adress</label>
                  <input type="text" placeholder="Street address">

                </div>

                <div class="contact-item">
                  
                  <input type="text" placeholder="Apartment, suit, unit, etc. (optional)">

                </div>

                <div class="contact-item">
                  
                  <label class="required">Town / City</label>
                  <input type="text" placeholder="Apartment, suit, unit, etc. (optional)">

                </div>

                <div class="contact-item">
                  
                  <div class="row">
                    
                    <div class="col-4">
                      
                      <label class="required">Postcode/ZIP</label>
                      <input type="text">

                    </div>

                  </div>

                </div>

                <div class="contact-item">
                  
                  <div class="row">
                    <div class="col-sm-6">
                      
                      <label class="required">Phone</label>
                      <input type="text">

                    </div>
                    <div class="col-sm-6">
                      
                      <label class="required">Email address</label>
                      <input type="text">

                    </div>
                  </div>

                </div>

                <div class="contact-item">
                  
                  <div class="input-wrapper">
                    <input type="checkbox" id="checkbox" name="checkbox">
                    <label for="checkbox">Create an account</label>
                  </div>

                </div>
              
              </form>

            </div>
            <div class="col-md-6 col-sm-12">
              
              <div class="checkbox-title input-wrapper style-2">

                <input type="checkbox" id="bd-diff-address" class="type-2" name="bd-diff-address">
                <label for="bd-diff-address">Ship to a Different Address?</label>

              </div>
              <form class="contact-form style-2">
                        
                <div class="contact-item">
                  
                  <label>Order Notes</label>
                  <textarea rows="4" placeholder="Notes about your order, e.g. special notes for delivery."></textarea>

                </div>

              </form>

            </div>
          </div>

        </div>

        <div class="content-element3">
          
          <h5>Your Order</h5>
          <div class="content-element4">
            
            <div class="shop-cart-form order-type table-type-1">
              <table>
                <tr>
                  <th class="product-col">Product</th>
                  <th class="total-col">Total</th>
                </tr>
                <tr>
                  <td data-title="Product">

                    <div class="product">

                      <div class="product-description">

                        <h6 class="product-name"><a href="#">Non Slip Yoga Mat</a></h6>

                      </div><!--/ .product-info -->

                    </div>

                  </td>
         
                  <td class="price total-price">$19.95</td>
                </tr>
                <tr>
                  <td data-title="Product">

                    <div class="product">

                      <div class="product-description">

                        <h6 class="product-name"><a href="#">Light Hard Foam <br> Yoga Blocks</a></h6>

                      </div><!--/ .product-info -->

                    </div>

                  </td>
         
                  <td class="price total-price">$11.35</td>
                </tr>
                <tr>
                  <td class="order-total">
                    <div>Cart subtotal:</div>
                  </td>
                  <td class="price total-price">$31.30</td>
                </tr>
                <tr>
                  <td class="order-total">
                    <div>Shipping:</div>
                  </td>
                  <td>
                    <div class="input-wrapper">

                      <input type="radio" checked name="radio-1" id="radio-1">
                      <label for="radio-1">Free shipping</label>

                    </div>
                    <div class="input-wrapper">

                      <input type="radio" name="radio-1" id="radio-2">
                      <label for="radio-2">Flat shipping</label>

                    </div>
                  </td>
                </tr>
                <tr class="bg-cell">
                  <td class="order-total">
                    <div class="fw-medium">Order Total:</div>
                  </td>
                  <td class="tprice">$31.30</td>
                </tr>
              </table>
            </div>

          </div>

          <div class="content-element4">
            
            <ul class="custom-list payment-option">

              <li>

                <div class="input-wrapper">
                  
                  <input type="radio" checked name="pm_payment_method" id="pm-cheque-payment" value="pm_cheque_payment">
                  <label for="pm-cheque-payment">Checkout Payments</label>

                </div><!--/ .input-wrapper -->

                <div class="payment-desc">
                  <p>Please send a check to Store Name, Store Street, Store Town, Store State / County, Store Postcode.</p>
                </div>

              </li>

              <li>

                <div class="input-wrapper">
                  
                  <input type="radio" name="pm_payment_method" id="pm-paypal" value="pm_paypal">
                  <label for="pm-paypal">PayPal</label>
                  <a href="#" class="link-text var2">What is PayPal?</a>

                </div><!--/ .input-wrapper -->

                <div class="payment-desc">

                  <img src="../../../assets/paypal.png" alt="">

                </div>

              </li>

              <li>
                
                <div class="input-wrapper style-2">
                  <input type="checkbox" id="checkbox2" class="type-2" name="checkbox2">
                  <label for="checkbox2">I’ve read and accept the <a href="#" class="link-text required">Terms & Conditions</a></label>
                </div>

              </li>

            </ul>

          </div>

          <a href="#" class="btn btn-style-3 btn-lg">Place Order</a>

        </div>

      </div>
      
    </div>

    <!-- - - - - - - - - - - - - end Content - - - - - - - - - - - - - - - -->    
</template>

<script>
import Header from '../header/Header';

export default {
    components: {
        Header
    }
}
</script>

<style>

</style>