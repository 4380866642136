<template>
<!--div id="wrapper" class="wrapper-container"-->

<div>
  <router-view />
</div>
  
</template>

<script>
//import Home from './components/Home'

export default {
  name: 'App',
  components: {
    //Home
  },
  created(){
  }
}
</script>