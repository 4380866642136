<template>
    <div>
        <Header/>
        <Breadcrumbs :name="nombre" :list="lista" />
        <div id="content" class="page-content-wrap">
            <div class="container">
                <Entry :entries="entradas"/>
            </div>
        </div>
        <Footer/>
    </div>        
</template>
<script>
import Header from './website/header/Header';
import Footer from './website/footer/Footer';
import Breadcrumbs from "./elements/Breadcrumbs"
import Entry from "./elements/Entry"

export default {
    data:function(){
        return {
            nombre:"Cursos",
            lista:[{
                name:"Home",
                url:"/",
            },{
                name:"Cursos",
                url:"/courses", 
            }],
            entradas:[{
                id:1,
                title:"Cannabis medicinal: extracción de aceite para uso terapéutico",
                img:"/img/medicinal.jpg",
                alt:"Spa",
                date:"",
                location:"Online",
                body:"<p>En este curso intensivo conocerás las propiedades terapéuticas del cannabis y sus aplicaciones para el tratamiento de diversas enfermedades como fibromialgia, esclerosis múltiple, cáncer, diabetes, artritis, reumatismo, colitis, afecciones de la piel, entre muchas otras. </p>"
            },{
                id:2,
                title:"Cosmética cannábica",
                img:"/img/cosmetica.jpg",
                alt:"Spa",
                date:"",
                location:"Online",
                body:"<p>Conoce los fundamentos científicos de la medicina cannabinoide para los tratamientos tópicos y cómo puede beneficiar tu piel. Aprenderás a realizar diferentes extracciones empleadas en la herbolaria, enfocadas en extracción de fitocannabinoides, y su empleo en la formulación de diversos productos de uso tópico y cosmético.</p>"
            },{
                id:3,
                title:"Cosmética Cannabica sólida",
                img:"/img/cosmetica-solida.jpg",
                alt:"Spa",
                date:"",
                location:"Online",
                body:"<p>En este taller aprenderás a formular y realizar productos cosméticos sólidos sin empaque, con ingredientes de origen natural, amigables con el cuerpo y el medio ambiente.</p>"
            },{
                id:4,
                title:"Cocina cannábica",
                img:"/img/cocina.jpg",
                alt:"Spa",
                date:"",
                location:"Online",
                body:"<p>En este curso práctico aprenderás a integrar el cannabis en tus alimentos y bebidas, además adquirirás los conocimientos para realizar formulaciones con dosificaciones precisas, para que puedas aprovechar al máximo su potencial nutricional y terapéutico.</p>"
            },{
                id:5,
                title:"Cultivo básico de cannabis",
                img:"/img/curso_4.jpg",
                alt:"Spa",
                date:"",
                location:"Online",
                body:"<p>En este taller aprenderás los principios básicos del cultivo de cannabis para uso terapéutico, aplicables tanto al cultivo en interior y exterior. Conocerás sobre la biología y fenología general de la planta, así como los cuidados básicos de cultivo.</p>"
            }
            ]
        }
    },
    components:{
        Header,
        Footer,
        Breadcrumbs,
        Entry
    }
}
</script>