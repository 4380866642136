<template>
    <div class="card bg-secondary text-white">
        <div class="card-body">
            <form action="" method="post">
                <div class="form-group" v-for="input in form" :key="input.index">
                    <label v-if=" input.type == 'text' || input.type == 'password' || input.type == 'email' " :for="input.id" v-html="input.label"></label>
                    <input v-if=" input.type == 'text' || input.type == 'password' || input.type == 'email' " :type="input.type" class="form-control" :id="input.id" :name="input.slug" :placeholder="input.placeholder" v-model="input.model" />
                    <small v-if=" input.type == 'text' || input.type == 'password' || input.type == 'email' " id="emailHelp" class="form-text text-muted" v-html="input.small"></small>
                </div>
                <button type="button" class="btn btn-outline-danger" v-on:click="cancel">Cancel</button>
                <button type="button" class="btn btn-outline-success" v-on:click="save" >Save</button>
            </form>
        </div>
    </div>
</template>
<script>
import axios from 'axios'
export default {
    props:{
        form:Array,
        url:String,
        headers:Object,
        data:Object,
        callback:String
	},
	methods: {
        save:function(){
            console.log(this.headers)
            var obj = {};
            this.form.forEach(element => {
                console.log(element.slug)
                obj[element.slug] = element.model
			});
            axios.post(this.url,obj,this.headers)
            .then((response)=>{
                console.log(response);              
            })
            .catch((error)=>{
                console.log(error.response )

            });
        },
        cancel:function(){
            console.log("canel");
        }
    }
}
</script>