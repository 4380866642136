<template>
    <div>
        <Header/>
        <Breadcrumbs :name="nombre" :list="lista" />
        <!-- - - - - - - - - - - - - - Content - - - - - - - - - - - - - - - - -->
        <!-- - - - - - - - - - - - - - Content - - - - - - - - - - - - - - - - -->

    <div id="content" class="page-content-wrap">

      <div class="container">

        <!-- <div id="options">
          <div id="filters" class="isotope-nav">
            <button class="is-checked" data-filter="*">All</button>
            <button data-filter=".category_2">Ashtanga</button>
            <button data-filter=".category_3">Gentle Yoga</button>
            <button data-filter=".category_4">Hatha Yoga</button>
            <button data-filter=".category_5">Vinyasa</button>
            <button data-filter=".category_6">Yin</button>
          </div>
        </div> -->
        
        <!-- Isotope -->
        <!--div class="isotope three-collumn clearfix team-holder" data-isotope-options='{"itemSelector" : ".item","layoutMode" : "fitRows","transitionDuration":"0.7s","fitRows" : {"columnWidth":".item"}}'-->
        <div class="row justify-content-center">
            <div class="team-item col-lg-4 col-md-4 col-sm-6">
              <a href="#" class="member-photo">
                <img src="../assets/equipo/paulina_mejia.jpg" alt="">
              </a>
              <div class="team-desc">
                <div class="member-info">
                  <h5 class="member-name"><a href="#">PhD. Paulina Mejía Correa</a></h5>
                  <h6 class="member-position">Directora científica</h6>
                </div>
              </div>
            </div>

            <div class="team-item col-lg-4 col-md-4 col-sm-6">
              <a href="#" class="member-photo">
                <img src="../assets/equipo/fernando_lujan.jpg" alt="">
              </a>
              <div class="team-desc">
                <div class="member-info">
                  <h5 class="member-name"><a href="#">Mtro. Luis Fernando Luján Ramírez</a></h5>
                  <h6 class="member-position">Presidente Instituto Multidisciplinario de Investigaciones Fitocannabinoides, AC.</h6>
                </div>
              </div>
            </div>
          </div>
      </div>
    </div>
    <Footer/>
  </div>    
</template>
<script>
import Header from './website/header/Header';
import Footer from './website/footer/Footer';
import Breadcrumbs from "./elements/Breadcrumbs"


export default {
    data:function(){
        return {
            nombre:"Nuestro Equipo",
            lista:[{
                name:"Home",
                url:"/",
            },{
                name:"Nuestro Equipo"
            }]
        }
    },
    components: {
        Header,
        Breadcrumbs,
        Footer
    }
}
</script>