<template>
	<div class="container-fluid p-0">
		<!-- Page section Heros -->
		<Heros v-bind:list="lista"/>
		<!--Team-->
		<Team v-bind:team="equipo"/>
		<!-- Contact us -->
		<div class="page-section parallax-section text-color-light" data-bg="../../../assets/1920x1000_bg222.jpg" style="background: #44af9b;">

			<div class="container wide2">

				<div class="row">
					<div class="col-lg-7 col-md-12">

						<div class="content-element5">
							<div class="section-pre-title">¿TENGO UNA PREGUNTA?</div>
							<h2 class="section-title">Contactanos</h2>
						</div>

						<p class="content-element3">No dude en enviarnos cualquier pregunta que pueda tener. Estamos felices de responderles.</p>

						<form id="contact-form" class="contact-form">

							<div class="contact-item">

							<label>Tu nombre (obligatorio)</label>
							<input type="text" name="cf-name" required="">

							</div>

							<div class="contact-item">

							<label>Tu Correo (obligatorio)</label>
							<input type="email" name="cf-email" required="">

							</div>

							<div class="contact-item">

							<label>Asunto</label>
							<input type="url" name="cf-subject">

							</div>

							<div class="contact-item">

							<label>Mensaje</label>
							<textarea rows="4" name="cf-message"></textarea>

							</div>

							<div class="contact-item">

							<button type="submit" class="btn btn-style-3" data-type="submit">Enviar</button>

							</div>

						</form>

					</div>

					<div class="col-lg-4 col-md-12 offset-lg-1">

						<div class="our-info style-2 vr-type var2">

							<!--div class="info-item">
							<i class="licon-map-marker"></i>
							<div class="wrapper">
								<span class="info-cat">Postal Address</span>
							</div>
							<h5 class="info-title">8901 Marmora Road, <br> Glasgow, D04 89GR.</h5>
							<a href="https://www.google.com/maps/dir//2032+S+Elliott+Ave,+Aurora,+MO+65605/@36.9487043,-93.7878472,12z/data=!3m1!4b1!4m8!4m7!1m0!1m5!1m1!1s0x87cf4b1a194c90e1:0xba30bfe0c0a857c!2m2!1d-93.7178072!2d36.9487249" class="btn btn-small btn-style-6">Get Direction</a>
							</div-->

							<div class="info-item">
							<i class="licon-telephone"></i>
							<div class="wrapper">
								<span class="info-cat">Número de teléfono</span>
							</div>
							<h5 class="info-title" content="telephone=no">+1 800 559 6580</h5>
							</div>

							<div class="info-item">
							<i class="licon-at-sign"></i>
							<div class="wrapper">
								<span class="info-cat">Correo electrónico</span>
							</div>
							<h5 class="info-title">info@divinorum.com</h5>
							</div>

							<div class="info-item">
							<i class="licon-clock3"></i>
							<div class="wrapper">
								<span class="info-cat">Horario de atención</span>
							</div>
							<h5 class="info-title">Lunes - Sabado: <br> 8am - 9pm</h5>
							</div>

						</div>

					</div>
				</div>

			</div>

		</div>
		<!-- Follow us -->
		<div class="page-section">

			<div class="container wide2">

			<div id="instafeed" class="instagram-feed style-2" data-instagram="5">

				<div class="insta-title">

				<i class="icon-instagram-5"></i>
				<h5>Síganos <br> en Instagram</h5>

				</div>

			</div>

			</div>

		</div>
		
	</div><!---container-fluid-->
</template>
<script>
import Heros from './HerosPart';
import Team from './TeamPart';

export default {
  data(){
    return{
		lista:[
			{
				id:1,
				title:"Salud integral",
				text:"Divinorum Spa Cannábico es un centro de salud integral.",
				url:"services/saludIntegral"
			},{
				id:2,
				title:"Terapias alternativas",
				text:"Desarrollamos terapías alternativas de caracter holístico e integral a base de cannabis.",
				url:"services/terapiasAlternativas"
			},{
				id:3,
				title:"Bienestar físico",
				text:"Terapias diseñadas especialmente para darte alivio y bienestar físico, mental y emocional.",
				url:"services/bienestarFisico"
			},{
				id:4,
				title:"Calidad de vida",
				text:"Nuestras terapias te ayudaran a restablecer tu salud y encontrar una mejor calidad de vida.",
				url:"services/calidadVida"
			}
		],
		equipo:[
			{
				id:1,
				image: 'http://localhost:8080/img/289x312_photo1.395b454a.jpg',
				name: 'Paulina Mejía',
				academictitle:'Bióloga, con doctorado en Ecología tropical por la UV',
			}
		]
    }
  },
  components:{
	Heros,
	Team
  }
}
</script>