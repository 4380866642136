<template>
	<table class="table table-sm">
		<thead>
			<tr>
				<th scope="col" v-for="thitem in  theads" :key="thitem.slug" v-html="thitem.title"></th>
				<th> <a :href="btnnew" class="btn btn-outline-success" >{{btntext}}</a></th>
			</tr>
		</thead>
		<tbody>
			<tr v-for="(row,index) in rows" :key="row.id"  :class="{'table-primary':(index%2==0)}" >
				<td v-for="thitem in  theads" :key="thitem.slug" v-html="row[thitem.slug]"></td>
				<td v-if="deleteitem">
					<button type="button" class="btn btn-secondary btn-sm">Delete</button>
				</td>
				<td v-if="edititem">
					<button type="button" class="btn btn-primary btn-sm">Edit</button>
				</td>
			</tr>
		</tbody>
	</table>
</template>
<script>
export default {
	props:{
		rows:Array,
		theads:Array,
		edititem:Boolean,
		deleteitem:Boolean,
		btnnew:String,
		btntext:String
	}
}
</script>