<template>
    <Header/>
    <div v-scroll="handleScroll" id="uhuhuuh" >
        <div id="spa_1" class="media-holder content_spa" style='background-image: url("/img/spab.jpg");padding: 280px 0 300px;'>
            <div class="container">
                <div class="align-center">
                    <div class="content-element3">
                        <h1 class="call-title">Divinorum Spa Cannábico</h1>
                        <br>
                        <p style="color:#222">Es un centro de
                            salud integral, en el que hemos desarrollado un conjunto
                            de terapias alternativas de caracter holístico e integral
                            a base de cannabis, en sinergia con lo mejor de la
                            herbolaria y la medicina tradicional mexicana y oriental,
                            diseñadas especialmente para proporcionarte <br> alivio y
                            bienestar físico, mental y emocional.
                        </p>
                        <p style="color:#222">
                            Las terapias son aplicadas por terapeutas profesionales
                            especialidados, quienes te ayudarán <br> a restablecer
                            tu salud y encontrar una mejor calidad de vida.
                        </p>
                    </div>
                    
                </div>
            </div>
        </div>
        <br>
        <br>
        <div id="spa_2" class=" content_spa">
            <div class="container">
                <div class="row">
                    <div class="col-lg-6 col-md-12" style='background-image: url("/img/terapia.jpg");background-position: center;background-size: cover;'>
                    </div>
                    <div class="col-lg-6 col-md-12" style="padding:100px 50px 100px;">
                        <h2>Terapia<br>Cannábica Integral</h2>
                        <br>                    
                        <p> Microdosis de CBD para alcanzar la relajación
                        profunda, aliviar la ansiedad y calmar el
                        dolor + Nebulización y aromaterapia con
                        terpenos + Masaje con sérum cannábico con
                        efecto relajante y analgésico + Infusión herbal
                        con fitocannabinoides y terpenos.</p>
                        <p><strong>Beneficios:</strong></p>
                        <ul style="list-style: inside;">
                            <li> Promueve la relajación y la liberación de tensión muscular.</li>
                            <li> Disminuye el estrés y la ansiedad.</li>
                            <li> Favorece el descanso.</li>
                            <li> Auxiliar en el alivio del dolor e inflamación.</li>
                            <li> Estimula el sistema inmunológico.</li>
                            <li> Aumenta la circulación sanguínea y estimula la eliminación de toxinas acumuladas en los músculos.</li>
                        </ul>
                        <br>
                        <p><strong> Costo: $550 MXN</strong></p>
                        <p><strong>Duración: 2 horas aproximadamente</strong></p>
                    </div>
                </div>
            </div>
        </div>
        <br>
        <br>
        <div id="spa_3" class="content_spa page-section-bg parallax-section" style='background-image: url("/img/masaje.jpg"); color:#fff;text-shadow: 2px 2px 3px #000;'>
            <div class="container" style="padding:0 0 100px 0">
                <h1 class="page-title" style="text-align: center; color:#fff;" >Otras Terapias</h1>
            </div>
            <div class="container">
                <div class="row">
                    <div class="col-lg-6 col-md-12">
                        <h3 style="text-align: center; color:#fff;">Terapia Cannábica + Limpieza facial Masaje Terapéutico con sérum cannábico</h3>
                        <br>
                        <p> Microdosis de CBD para alcanzar la relajación profunda,
                            aliviar la ansiedad o calmar el dolor + Nebulización y
                            aromaterapia con terpenos + Masaje relajante o
                            descontracturante con sérum cannábico con efecto
                            relajante y analgésico + Limpieza facial profunda + Infusión
                            herbal con fitocannabinoides y terpenos.</p>
                            <p><strong> Costo: $700 MXN</strong></p>
                            <p><strong>Duración: 3 horas aprox.</strong></p>                        
                    </div>                    
                    <div class="col-lg-6 col-md-12">
                        <br>
                        <h3 style="text-align: center; color:#fff;">Masaje Terapéutico con sérum cannábico</h3>
                        <br>
                        <p>Cualquiera de nuestros masajes (Relajante, Descon-
                            tracturante, Holístico o Deportivo) + Ventosas + Sérum
                            cannábico.</p>
                            <p><strong>Costo: $400 MXN</strong></p>
                            <p><strong>Duración: 1h:30 min. aprox.</strong></p>
                    </div>
                </div>
			</div>
        </div>
        <br>
        <br>
		<div id="spa_4" class="content_spa"  style="background: #fff;color:#333;">
			<div class="container">
                <div class="row">
                    <div class="col-lg-6 col-md-12"  style="padding:100px 50px 100px 0">
                        <br>
                        <h3 class="call-title">Masaje terapéutico + Limpieza Facial Profunda</h3>
                        <br>
                        <p>Masaje descontracturante o relajante y limpieza facial
                        (vaporización detox + exfoliación + extracción de puntos
                        negros + tónico + mascarilla cannábica + sérum nutritivo +
                        protección solar).</p>
                        <p><strong>Costo: $550 MXN</strong></p>
                        <p><strong>Duración: 2 horas aprox</strong></p>
                        <hr>
                        <br>
                        <h3 class="call-title">Reflexología podal</h3>
                        <br>
                        <p>Sesión de reflexología podal + tina de hidromasaje podal +
                        exfoliante cannábico + mantequilla corporal.</p>
                        <p><strong>Costo: $280 MXN</strong></p>
                        <p><strong>Duración: 1 hora.</strong></p>
                        <hr>
                        <br>
                        <h3 class="call-title">Limpieza Facial Profunda</h3>
                        <br>
                        <p>Vaporización detox + exfoliación + extracción de puntos
                        negros + tónico + mascarilla cannábica + sérum nutritivo +
                        protección solar.</p>
                        <p><strong>Costo: $300 MXN</strong></p>
                        <p><strong>Duración: 1 hora.</strong></p>
                    </div>                    
                    <div class="col-lg-6 col-md-12"  style='background-image: url("/img/piedras.jpg");background-position: center;background-size: cover;'>
                    </div>
                </div>
			</div>
		</div>
        <br>
        <br>
		<div id="spa_5" class="content_spa"  style="background: #fff;color:#333;">
            <div class="container">
                <div class="row">
                    <div class="col-lg-6 col-md-12"  style='background-image: url("/img/masajed.jpg");background-position: center;background-size: cover;'>
                    </div>
                    <div class="col-lg-6 col-md-12"  style="padding:100px 0 100px 50px">
                        <br>
                        <h3 class="call-title">Masaje Sueco</h3>
                        <br>
                        <p>Masaje relajante, se realiza en cama de masaje y con aceite
                        directamente sobre la piel.</p>
                        <p><strong>Costo: $400 mx.</strong></p>
                        <p><strong>Duración: 50 min.</strong></p>
                        <hr>
                        <br>
                        <h3 class="call-title">Nebulización con terpenos</h3>
                        <br>
                        <p>de Cannabis sativa(aceite esencial)
                        Ideal para tratamiento de afecciones respiratorias,</p>
                        <p><strong>estrés, ansiedad y fatiga.</strong></p>
                        <p><strong>Costo: $150 mx.</strong></p>
                        Duración: 25 minutos.
                        <hr>
                        <br>
                        <h3 class="call-title">Masaje Thai</h3>
                        <br>
                        <p>Masaje en colchoneta al nivel del piso, con ropa cómoda.
                        Ajuste estructural indoloro, relajante y descontructurante.</p>
                        <p><strong>Costo: $400 mx.</strong></p>
                        <p><strong>Duración: 50 min.</strong></p>
                    </div>
                </div>
            </div>
		</div>
        <br>
        <br>
        <div id="spa_6" class="media-holder content_spa parallax-section" style='background-image: url("/img/masajec.jpg");padding: 280px 0 300px;color:#fff;text-shadow: 2px 2px 3px #000;'>
            <div class="container">
                <div class="row">
                    <div class="col-lg-4 col-md-12">
                        <h3 style="text-align: center; color:#fff;">Reflexología General</h3>
                        <br>
                        <p>Masaje en espalda, hombros, cuello, 
                        rostro, manos y pies que busca estimular
                        los puntos refljos de los principales
                        órganos del cuerpo.</p>
                        <p><strong>Costo: $350 MXN</strong></p>
                        <p><strong>Duración: 5 min.</strong></p>
                    </div>
                    <div class="col-lg-4 col-md-12">
                        <h3 style="text-align: center; color:#fff;">Masaje de Espalda</h3>
                        <br>
                        <p>Masaje descontracturante y
                        relajante con aceite cannábico.</p>
                        <p><strong>Costo: $300 MXN</strong></p>
                        <p><strong>Duración: 5 min.</strong></p>
                    </div>
                    <div class="col-lg-4 col-md-12">
                        <h3 style="text-align: center; color:#fff;">Terapia Gota de Lluvia</h3>
                        <br>
                        <p>Aplicación de aceites sobre la columna vertebral
                        y los puntos reflejos de los pies.</p>
                        <p>Desinflama, fortalece el sistema inmunológico
                        y las vías respiratorias, suaviza contracturas,
                        mejora la digestión, restablece la
                        calma y la concentración.</p>
                        <p><strong>Costo: $350 MXN</strong></p>
                        <p><strong>Duración: 5 min.</strong></p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <Footer/>
</template>

<script>
import Header from './header/Header';
import Footer from './footer/Footer';
export default {
    components: {
        Header,
        Footer
    },
    data(){
        return {
            nombre:"Servicios",
            lista:[{
				name:"Home",
				url:"/",
			},{
				name:"Servicios",
				url:"/services/single_page/spa", 
			}],
            idService : this.$route.params.id
        }
    },
    mounted(){

        let id = this.idService;

        if(id != 'spa'){
            setTimeout(function(){
                let content = document.getElementById(id);
                content.scrollIntoView({block: 'start', behavior: 'smooth'}); 
            }, 500);            
        }
    },
    methods: {
        handleScroll: function() {

            let idContent = null;

            const testElements = document.getElementsByClassName('content_spa');
            Array.prototype.filter.call(testElements, function(testElement){

                let rect = testElement.getBoundingClientRect();

                //if (rect.y < 0 && rect.y >= (-1 * rect.height)){
                if (rect.y <= 0 && rect.bottom >= 0){
                    idContent = testElement.id;
                }  

            });

            if(idContent != null){
                this.$router.replace({ name: "SinglePageService", params: {id:idContent} })
            }else{
                this.$router.replace({ name: "SinglePageService", params: {id:"spa"} })
            }
        }
    }    
}

</script>

<style>

</style>