<template>
    <nav id="sidebarMenu" class="col-md-3 col-lg-2 d-md-block bg-dark text-white sidebar collapse">
        <div class="position-sticky pt-3">
            <ul class="nav flex-column">
                <li class="nav-item" v-for="item in menu" :key="item.title">
                    <a class="nav-link" :class="{active:item.activate}"  :href="item.url">
                        <i :class="item.class"></i>
                        {{item.title}}
                    </a>
                </li>
            </ul>
        </div>
    </nav>
</template>
<script>
export default {
    props:{
        itemSelect:String 
    },
    data:function(){
        return {
            menu:[{
                title:"Dashboard",
                url:"/admin",
                class:"fas fa-home"
            },{
                title:"User",
                url:"/admin/user",
                class:"fas fa-home"
            },{
                title:"Courses",
                url:"/admin/courses",
                class:"fas fa-home"
            }]
        }
    },
    mounted:function(){
        this.item_active()
    },
    methods: {
        item_active:function(){
            this.menu.forEach((element,index) => {
                if(element.title == this.itemSelect){
                    this.menu[index].activate = true;
                }else{
                    this.menu[index].activate = false;
                }
            });
        }
    }
}
</script>