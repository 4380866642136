import { createWebHistory, createRouter } from "vue-router";
import Home from "@/components/Home";
import AboutUs from "@/components/AboutUs"
import Team from "@/components/Team"
import Courses from "@/components/Courses"
import Single from "@/components/Single"
import Dashboard from "@/components/admin/Dashboard"

import Services from "@/components/website/main/Services"
//import BlogSimple from "@/components/website/main/BlogSimple"
import ShopCategory from "@/components/website/shop/ShopCategory"
import ShopSingle from "@/components/website/shop/ShopSingle"
import ShopCart from "@/components/website/shop/ShopCart"
import ShopCheckout from "@/components/website/shop/ShopCheckout"
import ShopAccount from "@/components/website/shop/ShopAccount"
import SinglePageService from "@/components/website/SinglePageService"
import BlogSmall from "@/components/website/blog/BlogSmall"
import BlogSingle from "@/components/website/blog/BlogSingle"

import User from "@/components/admin/User";

var intro = true;

const routes = [
	{
		path: "/",
		name: "Home",
		component: Home,
	},{
		path: "/about_us",
		name: "AboutUs",
		component:AboutUs
	},{
		path: "/team",
		name: "Team",
		component:Team
	},{
		path: "/courses",
		name: "Courses",
		component:Courses
	},{
		path: "/courses/:id",
		name: "Course",
		component:Single
	},{
		path: "/services/:id",
		name: "Services",
		component:Services
	},/*{
		path: "/blog",
		name: "BlogSimple",
		component:BlogSimple
	},*/{
		path: "/blog",
		name: "BlogSmall",
		component:BlogSmall
	},{
		path: "/blog/:id",
		name: "BlogSingle",
		component:BlogSingle
	},{
		path: "/shop_category",
		name: "ShopCategory",
		component:ShopCategory
	},{
		path: "/shop_single/:id",
		name: "ShopSingle",
		component:ShopSingle
	},{
		path: "/shop_cart",
		name: "ShopCart",
		component:ShopCart
	},{
		path: "/shop_checkout",
		name: "ShopCheckout",
		component:ShopCheckout
	},{
		path: "/shop_account",
		name: "ShopAccount",
		component:ShopAccount
	},{
		path: "/services/single_page/:id",
		name: "SinglePageService",
		component:SinglePageService
	},{ 
		path: '/admin', 
		component: Dashboard,
		beforeEnter(to, from, next){
			if(intro){
				next();
			}else{
				next('/home');
			}
		}
	},{
		path:'/admin/user',
		name:"",
		component:User,
		children:[{
			path: "new",
			name:"new",
			component:User
		},{
			path: "edit",
		}]
	}
];

const router = createRouter({
	history: createWebHistory(),
	routes,
});

export default router;