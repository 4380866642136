import { createApp } from 'vue'
import App from './App.vue'
import router from './router' // <---


import 'bootstrap/dist/css/bootstrap.min.css';
import 'font-awesome/css/font-awesome.min.css';
import './assets/css/fontello.css';
import './assets/css/responsive.css';
import './assets/css/dashboard.css';
import './assets/css/style.css';

import './assets/font/demo-files/demo.css';
//import './assets/css/owl.carousel.css';
//import './assets/plugins/owl.carousel.min.js';

//import './assets/js/libs/jquery-2.2.4.min.js';
//import './assets/js/libs/jquery.modernizr.js';
//import './assets/js/plugins.js';

const app = createApp(App).use(router);
//const app = createApp(App).use(router).mount('#app');

app.directive('scroll', {
    mounted(el, binding) {
        let f = function(evt) {
          if (binding.value(evt, el)) {
            window.removeEventListener('scroll', f);
          }
        };
        window.addEventListener('scroll', f);
      },
  });

app.mount('#app');

export default app;