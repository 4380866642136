<template>

    <Header/>
    <div class="breadcrumbs-wrap no-title">
      <div class="container">
        <ul class="breadcrumbs">
          <li><a href="/">Home</a></li>
          <li>Servicios</li>
          <li v-text="titleService"></li>
        </ul>
      </div>
    </div> 

    <div id="content" class="page-content-wrap">
        <div class="container">
            <div class="entry-box single-post">
                <div class="entry">

                    <div class="entry-body align-center">
                        <h1 class="entry-title" v-text="titleService"></h1>
                    </div>

                    <div class="thumbnail-attachment video-holder">
                        <img src="../../../assets/1140x650_img1.jpg" alt=""><a href="https://www.youtube.com/embed/oX6I6vs1EFs?rel=0&amp;showinfo=0&amp;autohide=2&amp;controls=0&amp;playlist=J2Y_ld0KL-4&amp;enablejsapi=1" class="video-btn" data-fancybox="video"></a>
                    </div>

                    <div class="row">
                        <div class="col-lg-8 col-md-12">
                            <p>Sed in lacus ut enim adipiscing aliquet. Nulla venenatis. In pede mi, aliquet sit amet, euismod in, auctor ut, ligula. Aliquam dapibus tincidunt metus. Praesent justo dolor, lobortis quis, lobortis dignissim, pulvinar ac, lorem. Vestibulum sed ante.</p> 
                        </div>

                        <aside id="sidebar" class="col-lg-4 col-md-12">
                            <div class="widget">
                                <div class="widget-bg">
                                    <div class="team-holder">
                                        <div class="team-item">      
                                            <a href="#" class="member-photo">
                                                <img src="../../../assets/100x100_photo4.jpg" alt="">
                                            </a>
                                            <div class="team-desc">
                                                <div class="member-info">
                                                    <h6 class="member-name"><a href="#">Vicky Johnson</a></h6>
                                                    <h6 class="member-position">Hatha Yoga Teacher</h6>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </aside>
                    </div>

                    <div class="row">
                        <div class="col-md-12">
                            <h4>Comentarios</h4>
                            <div style="width:100%;">
                                <div class="fb-comments" data-href="https://developers.facebook.com/docs/plugins/comments#configurator" data-width="100%" data-numposts="5"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Header from '../header/Header';

export default {
    components: {
        Header
    },
    data(){
        return{
            idService : this.$route.params.id,
            titleService : ''
        }
    },
    created(){
        switch(this.idService)
        {
            case 'saludIntegral':
                this.titleService = "Salud integral";
            break;

            case 'terapiasAlternativas':
                this.titleService = "Terapias alternativas";
            break;
            
            case 'bienestarFisico':
                this.titleService = "Bienestar físico";
            break; 
            
            case 'calidadVida':
                this.titleService = "Calidad de vida";
            break;   
            
            case '5':
                this.titleService = "Spa cannábico";
            break;  
            
            case '6':
                this.titleService = "Terapia cannábica integral";
            break;  

            case '7':
                this.titleService = "Nebulización con terpenos";
            break;  
            
        }
    }
}
</script>

<style>
.gravatar {
    float: left;
    width: 100px;
    margin-right: 20px;
}
.comments-list .children .comment {
    padding-left: 40px;
}

.comment > article {
    position: relative;
    padding: 25px 0;
    overflow: hidden;
}

.comment-reply-link {
    float: right;
    color: #45b29d;
    font-weight: 300;
    text-transform: uppercase;
    letter-spacing: .5px;
}

.btn {
    padding: 7px 30px 6px;
    display: inline-block;
    font-size: 14px;
    text-transform: uppercase;
    border-radius: 30px !important;
    background: #45b29d;
    color: #fff !important;
    font-weight: normal;
    text-align: center;
    line-height: 26px;
    letter-spacing: 0.8px;
}
h1 {
    font-size: 60px;
    line-height: 64px;
    letter-spacing: -0.1px;
}

.page-content-wrap {
    padding: 125px 0;
}
</style>