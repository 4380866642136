<template>
    <div>
        <Header/>
        <div class="container-fluid">
            <div class="row">
                <Sidebar :item-select="'User'"  />  
                <main class="offset-md-3  offset-lg-2 col-md-9 ms-sm-auto col-lg-10 px-md-4 pt-3">
                    <Form v-show="news"
                    :form="user"
                    :url="'http://localhost:5000/user'"
                    callback="'/admin'"
                    />
                    <Table v-show="list"
                    :theads="theads"
                    :rows="rows"
                    :btnnew="btnnew"
                    :btntext="btntext"
                    />
                </main>
            </div>
        </div>
    </div>    
</template>
<script>
import Header from './header/Header';
import Sidebar from './sidebar/Sidebar';
import Form from '../elements/Form';
import Table from '../elements/Table';
import axios from 'axios';

export default {
    data:function(){
        return {
            user:[{
                index:1,
                label:"Nombre",
                slug:'name',
                type:"text"
            },{
                index:2,
                label:"Primer Apellido",
                slug:'first_surname',
                type:"text"
            },{
                index:3,
                label:"Segundo Apellido",
                slug:'second_surname',
                type:"text"
            },{
                index:4,
                label:"Email",
                slug:'email',
                type:"email"
            },{
                index:5,
                label:"Password",
                slug:'password',
                type:"password"
            }],
            theads:[{
                title:"Id",
                slug:"user_id" 
            },{
                title:"Nombre",
                slug:"name" 
            },{
                title:"Primer Apellido",
                slug:"first_surname" 
            },{
                title:"Segundo Apellido",
                slug:"second_surname" 
            },{
                title:"Email",
                slug:"email" 
            }],
            rows:[],
            btntext:"New User",
            btnnew:"/admin/user/new"

        }
    },
    components: {
        Header,
        Sidebar,
        Form,
        Table
    },
    beforeCreate:function(){
        if(this.$router.currentRoute._value.name == 'new') this.news = true; 
        else this.news = false;
        if(this.$router.currentRoute._value.name == '') this.list = true;
        else this.list = false;

    },created:function(){
        console.log("aqi")
        if(this.$router.currentRoute._value.name == '') {
            axios.get('http://localhost:5000/user').then((response)=>{
                console.log(response.data.user)
                this.rows=response.data.user;
            }).catch((error)=>{
                console.log(error);
            })
        } 
        else this.list = false;

    },updated:function() {
        if(this.$router.currentRoute._value.name == 'new') this.news = true; 
        else this.news = false;
        if(this.$router.currentRoute._value.name == '') this.list = true;
        else this.list = false;
        console.log(this.$router.currentRoute._value.name);
    }
}
</script>