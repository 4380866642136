<template>
  <Header />

  <!-- - - - - - - - - - - - - - Breadcrumbs - - - - - - - - - - - - - - - - -->
  <div class="breadcrumbs-wrap no-title">
    <div class="container">
      <ul class="breadcrumbs">
        <li><a href="/">Home</a></li>
        <li><a href="#">Tienda en linea</a></li>
        <li><a href="#">Productos</a></li>

        <li>{{ producto.name }}</li>

      </ul>
    </div>
  </div>
  <!-- - - - - - - - - - - - - end Breadcrumbs - - - - - - - - - - - - - - - -->

  <!-- - - - - - - - - - - - - - Content - - - - - - - - - - - - - - - - -->

  <div id="content" class="page-content-wrap">
    <!--transition name="fade" mode="out-in" appear-->
    <div v-if="loadingPost" class="text-center mt-5">
      <Loading />
    </div>


    <div class="container wide3">

      <div class="content-element4">

        <div class="product single">

          <div class="row">
            
            <div class="col-md-6 col-sm-12">

              <div class="image-preview-container">

                <!-- - - - - - - - - - - - - - Image Preview Container - - - - - - - - - - - - - - - - -->
                <div v-if="producto['images'] !== undefined">
                   <figure class="image-preview">
                      <img :src="producto.images[0].url" alt="">
                    </figure>
                </div>
                <div v-else >
                    <figure class="image-preview">
                      <img src="../../../assets/logob.jpeg" alt="">
                    </figure>

                </div><!--/ .image-preview-->

                <!-- - - - - - - - - - - - - - End of Image Preview Container - - - - - - - - - - - - - - - - -->

                  <!--<div class="carousel-type-2">

                      <div class="carousel-type-2">

                      <div class="owl-carousel type-small product-thumbs" id="thumbnails" data-max-items="4">

                    <a class="active" href="#" data-image="../../../assets/608x598_product1.jpg"
                      data-zoom-image="../../../assets/608x598_product1.jpg">

                      <img src="../../../assets/130x130_product1.jpg" alt="">

                    </a>

                    <a href="../../../assets/608x598_product2.jpg" data-image="../../../assets/608x598_product2.jpg"
                      data-zoom-image="../../../assets/608x598_product2.jpg" data-fancybox="group">

                      <img src="../../../assets/130x130_product2.jpg" alt="">

                    </a>

                    <a href="../../../assets/608x598_product3.jpg" data-image="../../../assets/608x598_product3.jpg"
                      data-zoom-image="../../../assets/608x598_product3.jpg" data-fancybox="group">

                      <img src="../../../assets/130x130_product3.jpg" alt="">

                    </a>

                    <a href="../../../assets/608x598_product4.jpg" data-image="../../../assets/608x598_product4.jpg"
                      data-zoom-image="../../../assets/608x598_product4.jpg" data-fancybox="group">

                      <img src="../../../assets/130x130_product4.jpg" alt="">

                    </a>

                  </div>-->

                </div>

                <!-- - - - - - - - - - - - - - End of Thumbnails - - - - - - - - - - - - - - - - -->

              </div><!--/ .image-preview-container -->

            <!--</div>-->

            <div class="col-md-6 col-sm-12">
             
              <!-- - - - - - - - - - - - - - Product Description - - - - - - - - - - - - - - - - -->

                  <h1 class="product-name"> {{ producto.name }} </h1>
                  <p class="product-excerpt"> </p>
                  <div class="pricing-area justify-content-start">

                 <h1 class="product-name">  </h1>
                <p class="product-excerpt">  </p>
                  <div class="pricing-area justify-content-start">

                  <!--<ul class="rating">
                      <li><i class="licon-star"></i></li>
                      <li><i class="licon-star"></i></li>
                      <li><i class="licon-star"></i></li>
                      <li class="empty"><i class="licon-star"></i></li>
                      <li class="empty"><i class="licon-star"></i></li>
                    </ul>
                    <span>(<a href="#" class="link-text">2 customer reviews</a>)</span>-->
                  </div>

                <p class="product-excerpt"> </p>

                <p class="product-excerpt"></p>

                <form class="add-to-cart-form">

                  <div class="product-actions">

                     <!--<div class="quantity">
                        <input type="text" value="1"  readonly="">
                        <button type="button" class="qty-plus"><i class="licon-plus"></i></button>
                        <button type="button" class="qty-minus"><i class="licon-minus"></i></button>
                     </div>-->
                    <a href="https://divinorumboutiqueherbal.alegratienda.com/"
                      class="btn btn-style-3 btn-big">Comprar</a>

                  </div><!--/ .product-actions -->

                </form>
                <!--
                  <ul class="product-meta">

                    <li><span>SKU:</span> 123</li>
                    <li><span>Category:</span> <a href="#">Equipment</a></li>
                    <li><span>Tags:</span> &nbsp;
                      <a href="#">mat</a>,
                      <a href="#">pink</a>,
                      <a href="#">equipment</a>
                    </li>

                  </ul>-->

                <div class="share-wrap var2">
                    
                   <!-- <span class="share-title">Share this:</span>
                    <ul class="social-icons share">
                  
                      <li><a href="#" class="sh-facebook"><i class="icon-facebook"></i></a></li>
                      <li><a href="#" class="sh-twitter"><i class="icon-twitter"></i></a></li>
                      <li><a href="#" class="sh-google"><i class="icon-gplus-3"></i></a></li>
                      <li><a href="#" class="sh-pinterest"><i class="icon-pinterest"></i></a></li>
                      <li><a href="#" class="sh-mail"><i class="icon-mail"></i></a></li>
            
                    </ul>-->
            
                  </div>

              </div>

                <div class="tabs tabs-section">
                  <!--tabs navigation-->                  
                  <ul class="tabs-nav clearfix">
                    <li>
                      <a href="#tab-1">Description</a>
                    </li>
                  <!--  <li>
                      <a href="#tab-2">Reviews</a>
                    </li>-->
                  </ul>
                  <!--tabs content-->                 
                  <div class="tabs-content">
                    <div id="tab-1">

                      <p>
                          {{ producto.description }}
                      </p>

                  </div>
                  <div id="tab-2">

                    <!--<div class="content-element6">
                        
                        <h5 class="content-element2">2 Reviews For Men’s T-shirt Whit</h5>

                        <ol class="comments-list">

                          <li class="comment">

                            <article>-->

                    <!-- - - - - - - - - - - - - - Avatar - - - - - - - - - - - - - - - - -->

                    <!--<div class="gravatar">
                                
                                <a href="#"><img src="../../../assets/100x100_photo2.jpg" alt=""></a>

                              </div>-->

                    <!-- - - - - - - - - - - - - - End of avatar - - - - - - - - - - - - - - - - -->

                    <!-- - - - - - - - - - - - - - Comment body - - - - - - - - - - - - - - - - -->

                    <!--<div class="comment-body">

                                <header class="comment-meta">

                                  <h6 class="comment-author"><a href="#">Camala Haddon</a></h6>
                                  <div class="comment-info flex-row justify-content-between">
                                    <time datetime="2015-10-17 02:41" class="comment-date">October 17, 2017 at 2:41 pm</time>
                                    <ul class="rating">
                                      <li><i class="licon-star"></i></li>
                                      <li><i class="licon-star"></i></li>
                                      <li><i class="licon-star"></i></li>
                                      <li class="empty"><i class="licon-star"></i></li>
                                      <li class="empty"><i class="licon-star"></i></li>
                                    </ul>
                                  </div>

                                </header>

                                <p>Aenean auctor wisi et urna. Aliquam erat volutpat. Duis ac turpis. Donec sit amet eros.</p>

                              </div>--><!--/ .comment-body-->

                    <!-- - - - - - - - - - - - - - End of comment body - - - - - - - - - - - - - - - - -->

                    <!--</article>

                          </li>

                          <li class="comment">

                            <article>-->

                    <!-- - - - - - - - - - - - - - Avatar - - - - - - - - - - - - - - - - -->

                    <!--<div class="gravatar">
                                
                                <a href="#"><img src="../../../assets/100x100_photo3.jpg" alt=""></a>

                              </div>-->

                    <!-- - - - - - - - - - - - - - End of avatar - - - - - - - - - - - - - - - - -->

                    <!-- - - - - - - - - - - - - - Comment body - - - - - - - - - - - - - - - - -->

                    <!--<div class="comment-body">

                                <header class="comment-meta">

                                  <h6 class="comment-author"><a href="#">Bradley Grosh</a></h6>
                                  <div class="comment-info flex-row justify-content-between">
                                    <time datetime="2015-10-17 02:41" class="comment-date">October 17, 2017 at 2:41 pm</time>
                                    <ul class="rating">
                                      <li><i class="licon-star"></i></li>
                                      <li><i class="licon-star"></i></li>
                                      <li><i class="licon-star"></i></li>
                                      <li><i class="licon-star"></i></li>
                                      <li><i class="licon-star"></i></li>
                                    </ul>
                                  </div>

                                </header>

                                <p>Ut tellus dolor, dapibus eget, elementum vel, cursus eleifend, elit. Aenean auctor wisi et urna. Aliquam erat volutpat. Duis ac turpis. </p>

                              </div>--><!--/ .comment-body-->

                    <!-- - - - - - - - - - - - - - End of comment body - - - - - - - - - - - - - - - - -->

                    <!--</article>

                          </li>
                      
                        </ol>

                      </div>-->

                    <!--<div class="content-element6">
                
                        <h5 class="title">Add a Review</h5>
                        <p class="text-size-small">Your email address will not be published. Fields marked with an <span class="required"></span> are required.</p>
                        <form class="contact-form style-2">
                          <div class="contact-item">
                        
                            <label class="required">Your Name</label>
                            <input type="text">
                      
                          </div>
                          <div class="contact-item">
                      
                            <label class="required">Your Email</label>
                            <input type="email">
                      
                          </div>
                          <div class="contact-item">
                      
                            <label>Rating</label>
                            <ul class="rating size-2">
                              <li><i class="licon-star"></i></li>
                              <li><i class="licon-star"></i></li>
                              <li><i class="licon-star"></i></li>
                              <li><i class="licon-star"></i></li>
                              <li class="empty"><i class="licon-star"></i></li>
                            </ul>
                      
                          </div>
                          <div class="contact-item">
                    
                            <label class="required">Comment</label>
                            <textarea rows="4"></textarea>
                      
                          </div>
                          <div class="contact-item">
                            <button type="submit" class="btn btn-style-3" data-type="submit">Submit Review</button>
                          </div>
                        </form>

                      </div>-->

                  </div>
                </div>
              </div>

              <!-- - - - - - - - - - - - - - End of Product Description - - - - - - - - - - - - - - - - -->
</div>
            </div>

          </div><!--/ .row -->

        <h4 class="section-title">  </h4>

      </div>

      <h4 class="section-title"></h4>

      <!-- <div class="products-holder flex-row item-col-4">
              
          <div class="product">-->

      <!-- - - - - - - - - - - - - - Product Image - - - - - - - - - - - - - - - - -->
      <!--<figure class="product-image">
              <span class="label sale">Sale</span>
              <a href="#"><img src="../../../assets/289x286_img9.jpg" alt=""></a>
            </figure>-->
      <!-- - - - - - - - - - - - - - End of Product Image - - - - - - - - - - - - - - - - -->

      <!-- - - - - - - - - - - - - - Product Description - - - - - - - - - - - - - - - - -->
      <!--<div class="product-description">
        
              <a href="#" class="product-cat">Equipment</a>
        
              <h6 class="product-name"><a href="#">Big Yoga Bag</a></h6>
        
              <div class="pricing-area">
        
                <div class="product-price">
                  <span>$48.95</span> $38.95
                </div>-->

      <!--<ul class="rating">
                  <li><i class="licon-star"></i></li>
                  <li><i class="licon-star"></i></li>
                  <li><i class="licon-star"></i></li>
                  <li><i class="licon-star"></i></li>
                  <li><i class="licon-star"></i></li>
                </ul>-->

      <!--</div>
        
              <a href="#" class="btn btn-small btn-style-3">Comprar</a>
        
            </div>-->
      <!-- - - - - - - - - - - - - - End of Product Description - - - - - - - - - - - - - - - - -->

      <!--</div>

          <div class="product">-->

      <!-- - - - - - - - - - - - - - Product Image - - - - - - - - - - - - - - - - -->
      <!--<figure class="product-image">
              <a href="#"><img src="../../../assets/289x286_img10.jpg" alt=""></a>
            </figure>-->
      <!-- - - - - - - - - - - - - - End of Product Image - - - - - - - - - - - - - - - - -->

      <!-- - - - - - - - - - - - - - Product Description - - - - - - - - - - - - - - - - -->
      <!--<div class="product-description">
        
              <a href="#" class="product-cat">Clothes</a>
        
              <h6 class="product-name"><a href="#">Sport Yoga Bra</a></h6>
        
              <div class="pricing-area">
        
                <div class="product-price">
                  $14.99
                </div>
        
                <ul class="rating">
                  <li><i class="licon-star"></i></li>
                  <li><i class="licon-star"></i></li>
                  <li><i class="licon-star"></i></li>
                  <li><i class="licon-star"></i></li>
                  <li><i class="licon-star"></i></li>
                </ul>
        
              </div>
        
              <a href="#" class="btn btn-small btn-style-3">Comprar </a>
        
            </div>-->
      <!-- - - - - - - - - - - - - - End of Product Description - - - - - - - - - - - - - - - - -->

      <!--</div>

          <div class="product">-->

      <!-- - - - - - - - - - - - - - Product Image - - - - - - - - - - - - - - - - -->
      <!--<figure class="product-image">
              <a href="#"><img src="../../../assets/289x286_img11.jpg" alt=""></a>
            </figure>-->
      <!-- - - - - - - - - - - - - - End of Product Image - - - - - - - - - - - - - - - - -->

      <!-- - - - - - - - - - - - - - Product Description - - - - - - - - - - - - - - - - -->
      <!--<div class="product-description">
        
              <a href="#" class="product-cat">Equipment</a>
        
              <h6 class="product-name"><a href="#">Creatine Powder For Building Muscle & Losing Weight</a></h6>
        
              <div class="pricing-area">
        
                <div class="product-price">
                  $25.99
                </div>
        
              </div>
        
              <a href="#" class="btn btn-small btn-style-3">Add To Cart</a>
        
            </div>-->
      <!-- - - - - - - - - - - - - - End of Product Description - - - - - - - - - - - - - - - - -->

      <!--</div>

          <div class="product">-->

      <!-- - - - - - - - - - - - - - Product Image - - - - - - - - - - - - - - - - -->
      <!--<figure class="product-image">
              <a href="#"><img src="../../../assets/289x286_img8.jpg" alt=""></a>
            </figure>-->
      <!-- - - - - - - - - - - - - - End of Product Image - - - - - - - - - - - - - - - - -->

      <!-- - - - - - - - - - - - - - Product Description - - - - - - - - - - - - - - - - -->
      <!--<div class="product-description">
        
              <a href="#" class="product-cat">Equipment</a>
        
              <h6 class="product-name"><a href="#">Yoga Cushion</a></h6>
        
              <div class="pricing-area">
        
                <div class="product-price">
                  $25.99
                </div>
        
              </div>
        
              <a href="#" class="btn btn-small btn-style-3">Add To Cart</a>
        
            </div>-->
      <!-- - - - - - - - - - - - - - End of Product Description - - - - - - - - - - - - - - - - -->

      <!--</div>-->

      <!--</div>-->

    </div>

  </div>

  <!-- - - - - - - - - - - - - end Content - - - - - - - - - - - - - - - -->
  <Footer />
</template>
<script>
import Header from '../header/Header';
import Loading from '../../elements/Loading';
import axios from 'axios';
import Footer from '../footer/Footer';

export default {
  components: {
    Header,
    Loading,
    Footer

  },
  data() {

    return {
      idproducto: this.$route.params.id,
      producto: {},
      author: '',
      totalItems: 0,
      loadingPost: true
    }
  },
  created() {
    this.getPost();
  },
  methods: {
    getPost() {
      let me = this;
      // Make a request for a user with a given ID

      //axios.get(`https://www.googleapis.com/blogger/v3/blogs/8002308099204365303/posts/${ this.idPost }?key=AIzaSyBxYlJqR7PD1ubH00UMHjszJlB6LRpyzvo`)
      axios.get(`https://store.divinorum.com.mx/wp-json/dv/product/${this.idproducto}`)
        .then(function (response) {
          me.producto = response.data;
        })
        .catch(function (error) {
          // handle error
          console.log(error);
        })
        .then(() => {
          me.loadingPost = false;
        });
    }
  },
  computed: {
    datePublish() {
      return param => {
        let d = new Date(param);
        let options = { weekday: "long", year: "numeric", month: "long", day: "numeric" };
        let n = d.toLocaleDateString("es-ES", options);

        return n;
      };
    },
    findImage() {
      return param => {
        let content = param;
        let temporal = document.createElement("div");
        temporal.innerHTML = content;
        let firstImage = temporal.getElementsByTagName('img')[0];
        let rawImgSrc = firstImage ? firstImage.getAttribute("src") : false;

        return rawImgSrc;
      }
    }
  }
}

</script>

<style></style>