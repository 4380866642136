<template>
  <footer id="footer" class="footer">
    <div class="logo"><img src="../../../assets/logo.png" alt="" /></div>
    <div class="container wide-style">
      <!-- main footer -->
      <div class="main-footer">
        <div class="row">
          <div class="col-lg-4 col-md-12">
            <!--<div class="widget">
              <h6 class="widget-title">Recibe nuestras noticias</h6>
            </div>-->
          </div>
          <div class="col-lg-4 offset-lg-4 col-md-12">
            <!--<div class="widget">
              <h6 class="widget-title">¡Sigamos en contacto!</h6>
              <ul class="social-icons">
                <li>
                  <a
                    target="_blank"
                    href="https://www.facebook.com/DivinorumBotiqueHerbal"
                    ><i class="icon-facebook"></i
                  ></a>
                </li>
                <li>
                  <a
                    target="_blank"
                    href="https://www.instagram.com/divinorumboutiqueherbal"
                    ><i class="icon-instagram-5"></i
                  ></a>
                </li>
              </ul>
            </div>-->
          </div>
        </div>
      </div>
      <ul class="menu-list">
        <li><a href="/">Home</a></li>
        <li><a href="/aubut_us">¿Quiénes somos?</a></li>
        <li><a href="courses">Cursos y Talleres</a></li>
        <li><a href="/single_page/spa">Servicios</a></li>
        <li><a href="blog">blog</a></li>
        <li><a href="shop_category">Tienda en linea</a></li>
        <!--li><a href="#">Productos</a></li-->
      </ul>
      <div class="copyright">
        <p>Copyright © 2021 Divinorum. Todos los derechos reservados.</p>
      </div>
    </div>
  </footer>
</template>
